import React, {useContext, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import {isEmpty} from "../Utils";
import {MyContext} from "../../contexts/MyContext";

const Delete = () => {
    const translationsReducer = useSelector(
        (state) => state.langReducer.translationsReducer
    );
    const { removeAccount, logoutUser } = useContext(MyContext);
    const translations = translationsReducer.translate;
    const [modal, setModal] = useState(false);
    const [password, setPassword] = useState(null);
    const [errorPasswordLogin, setErrorPasswordLogin] = useState(false);
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    const [styles, setStyles] = useState({});

    useEffect(() => {
        if (settings?.general_text_color) {
            setStyles((prevState) => ({...prevState, borderColor: settings.general_text_color}));
            setStyles((prevState) => ({...prevState, color: settings.general_text_color}));
        }
    }, [settings]);

    const click = () => {
        setModal(!modal);
    };

    const remove = async (event) => {
        event.preventDefault();
        if (!isEmpty(password) && password.length > 3) {
            const data = await removeAccount(password);
            if (data.result === "ok") {
                logoutUser();
                window.location.href = "/";
            } else {
                setErrorPasswordLogin(true);
                document.querySelector("#password").parentElement.classList.add("error");
            }
        }
    };

    const onChangeValue = (password) => {
        setPassword(password);
        setErrorPasswordLogin(false);
        document.querySelector("#password").parentElement.classList.remove("error");
    };

    return (
        <div id="delete">
            {!modal &&
                <button onClick={click} style={styles}>
                    {translations && !isEmpty(translations["delete_account"])
                        ? translations["delete_account"]
                        : "Delete account"
                    }
                </button>
            }

            {modal &&
                <div className="modal-delete">
                    <div className="input select">
                        <label>
                            {translations && !isEmpty(translations["password"])
                                ? translations["password"]
                                : "Password"}{" "}
                            *
                        </label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            placeholder={
                                translations && !isEmpty(translations["register_password_ph"])
                                    ? translations["register_password_ph"]
                                    : "Password"
                            }
                            required
                            autoFocus
                            onChange={(e) => onChangeValue(e.target.value)}
                        />
                        {errorPasswordLogin && (
                            <div className="errorDisplay">
                                <p>Password Error !</p>
                            </div>
                        )}
                    </div>

                    <div className="button-block">
                        <button onClick={click} className="cancel" style={styles}>
                            {translations && !isEmpty(translations["cancel"])
                                ? translations["cancel"]
                                : "Cancel"}
                        </button>
                        <button onClick={remove} style={styles}>{translations && !isEmpty(translations["delete_account"])
                            ? translations["delete_account"]
                            : "Delete"}
                        </button>
                    </div>

                </div>
            }
        </div>
    );
};

export default Delete;
