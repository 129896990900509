import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Switch from "react-switch";
import { MyContext } from "../../contexts/MyContext";
import { isEmpty } from "../Utils";

const NotificationParam = () => {
	const [notificationEmail, setNotificationEmail] = useState(false);
	const [notificationPush, setNotificationPush] = useState(false);
	const [receiveNews, setReceiveNews] = useState(false);
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const { rootState, updateUserNotif, isLoggedIn } = useContext(MyContext);
	const { theUser } = rootState;
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);

	useEffect(() => {
		if (!isEmpty(theUser)) {
			theUser.email_notif === "1"
				? setNotificationEmail(true)
				: setNotificationEmail(false);
			theUser.push_notif === "1"
				? setNotificationPush(true)
				: setNotificationPush(false);
			theUser.receive_news === "1"
				? setReceiveNews(true)
				: setReceiveNews(false);
		}
	}, [theUser]);

	const updateNotification = async (type, value) => {
		let valueNotif = value ? "1" : "0";
		const data = await updateUserNotif(type, valueNotif);
		if (data.result === "ok") {

			setTimeout(() => {
				document.querySelector(".notif > #validUpdate1").classList.add("display");
			}, 60);

			setTimeout(() => {
				if (document.querySelector(".notif > #validUpdate1")) {
					document.querySelector(".notif > #validUpdate1").classList.remove("display");
				}
			}, 600);

			await isLoggedIn();

			console.log("success");
		} else {
			console.log(data.message);
		}
	};

	return (
		<section className="notifications block" style={{
			color: settings?.menu_text_color ? settings.menu_text_color : "white",
			background: !isEmpty(settings) ? settings.accent_color : ""
		}}>
			<div className="title">
				<h3>
					{translations && !isEmpty(translations["notifications"])
						? translations["notifications"]
						: "Notifications"
					}
				</h3>
			</div>

			<div className="notif">
			<article className="newsletter">
				<div>
					<h4>
						{translations && !isEmpty(translations["agree_receive_news"])
							? translations["agree_receive_news"]
							: "I agree to receive news"
						}
					</h4>
					<Switch
						onChange={() => updateNotification("receive_news", !receiveNews)}
						checked={receiveNews}
						uncheckedIcon={false}
						checkedIcon={false}
						offColor="#d6d6d6"
						onColor="#679bff"
						id="agree_receive_news"
					/>
				</div>
				<div>
					<h4>
						{translations && !isEmpty(translations["notifications_by_email"])
							? translations["notifications_by_email"]
							: "Notifications by email"
						}
					</h4>
					<Switch
						onChange={() => updateNotification("email_notif", !notificationEmail)}
						checked={notificationEmail}
						uncheckedIcon={false}
						checkedIcon={false}
						offColor="#d6d6d6"
						onColor="#679bff"
						id="newsletterNotif"
					/>
				</div>
				<div>
					<h4>
						{translations && !isEmpty(translations["notifications_push"])
							? translations["notifications_push"]
							: "Notifications push"
						}
					</h4>
					<Switch
						onChange={() => updateNotification("push_notif", !notificationPush)}
						checked={notificationPush}
						uncheckedIcon={false}
						checkedIcon={false}
						offColor="#d6d6d6"
						onColor="#679bff"
						id="partnerNotif"
					/>
				</div>
				
			</article>
				<div id="validUpdate1">
							{translations && !isEmpty(translations["notifications_update"])
								? translations["notifications_update"]
								: "Notifications have been updated"
							}
				</div>
			</div>
		</section>
	);
};

export default NotificationParam;
