import axios from "axios";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const getNotifications = () => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			let form = new FormData();
			form.append("key", loginToken);
			form.append("r", "no");
			let url = `${process.env.REACT_APP_API_URL}getnotifs`;
			await axios.post(url, form)
				.then((res) => dispatch({type: GET_NOTIFICATIONS, payload: res.data}))
				.catch((error) => {
					console.log(error)
				});
		}

		return false;
	};
};
