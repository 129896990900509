import axios from "axios";

export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENT = "GET_EVENT";
export const CLEAR_EVENT = "CLEAR_EVENT";

export const getEventsLive = (quantity = 30, page = 0, type = 0) => {
	return async (dispatch) => {
		await axios.get(
				`${process.env.REACT_APP_API_URL}getallLives&p=${page}&n=${quantity}&t=${type}`
			).then((res) => dispatch({ type: GET_EVENTS, payload: res.data }))
			.catch((error) => {
				console.log(error)
			});

	};
};

export const clearEvent = () => {
	return async (dispatch) => {
		dispatch({type: CLEAR_EVENT, payload: []});
	};
};

export const getEvent = (eventId) => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		let url = `${process.env.REACT_APP_API_URL}getLive&i=${eventId}`;
		if (loginToken) {
			url += `&key=${loginToken}`
		}
		await axios.get(url)
			.then((res) => dispatch({ type: GET_EVENT, payload: res.data }))
			.catch((error) => {
				console.log(error)
			});

	};
};

