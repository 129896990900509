import React, { createContext, Component } from "react";
import axios from "axios";
import {isEmpty} from "../components/Utils";

export const MyContext = createContext();

//DEFINE URL

const Axios = axios.create();

class MyContextProvider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAuth: null,
			theUser: null,
			redirect: null
		};
		//this.isLoggedIn(this);
	}

	registerUser = async (user) => {
		let form = new FormData();
		form.append("e",  user.email);
		form.append("u",  user.username);
		form.append("p",  user.password);
		form.append("l",  user.selectedLanguage);
		form.append("n",  user.receiveNews === true ? "1" : "0");
		const register = await Axios.post(`${process.env.REACT_APP_API_URL}register`, form);
		return register.data;
	};

	logoutUser = () => {
		localStorage.removeItem("loginToken");
		if (!isEmpty(localStorage.getItem('loginRedirectUrl'))) {
			window.location.href = localStorage.getItem('loginRedirectUrl');
		}
		this.setState({
			...this.state,
			isAuth: false,
			redirect: true
		});
	};

	loginUser = async (user) => {
		let form = new FormData();
		form.append("u",  user.email);
		form.append("p",  user.password);
		const login = await Axios.post(`${process.env.REACT_APP_API_URL}login`, form);
		return login.data;
	};

	isLoginAuth = async () => {
		const isLogged = localStorage.getItem("loginToken");
		if (isLogged) {
			try {
				Axios.defaults.headers.common["Authorization"] = isLogged;
				const {data} = await Axios.get(`${process.env.REACT_APP_API_URL}userInfo`);
				return !!(data.success && data.user);
			} catch (e) {
				return false;
			}
		} else {
			return false;
		}
	};

	isLoggedIn = async () => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append('key',  loginToken);
				const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}loginwk`, form);
				if (data.result === "ok" && data.data) {
					this.setState({
						...this.state,
						isAuth: true,
						theUser: data.data,
						redirect: false
					});
				} else {
					this.setState({
						...this.state,
						isAuth: false,
						theUser: null,
						redirect: true
					});
					return false;
				}
			} catch (e) {
				this.setState({
					...this.state,
					isAuth: false,
					theUser: null,
					redirect: true
				});
				return false;
			}

		} else {
			this.setState({
				...this.state,
				isAuth: false,
				theUser: null,
				redirect: true
			});
			return false;
		}
	};

	updateUserData = async (user) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append("key", loginToken);
				form.append("n", user.name);
				form.append("u", user.username);
				form.append("l", user.lang);
				const update = await Axios.post(`${process.env.REACT_APP_API_URL}changeaccdetails`, form);
				return update.data;
			} catch (e) {
				console.log(e);
			}
		}
		return false;
	};

	updateEmail = async (email) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append("key", loginToken);
				form.append("e", email);
				const update = await Axios.post(`${process.env.REACT_APP_API_URL}change_email`, form);
				return update.data;
			} catch (e) {
				console.log(e);
			}
		}
		return false;
	};

	updateAvatar = async (formData) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				formData.append("key", loginToken);
				const update = await Axios.post(
					`${process.env.REACT_APP_API_URL}changeavatar`,
					formData,
					{
						headers: {
							"content-type": "multipart/form-data",
						},
					}
				);

				return update.data;

			} catch (e) {
				console.log(e);
			}
		}

		return false;
	};

	updatePassword = async (oldPass, newPass, confPass) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append("key", loginToken);
				form.append("o", oldPass);
				form.append("n", newPass);
				form.append("c", confPass);
				const update = await Axios.post(`${process.env.REACT_APP_API_URL}changepass`, form);
				return update.data;
			} catch (e) {
				console.log(e);
			}
		}
		return false;
	};

	updateUserNotif = async (type, value) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append("key", loginToken);
				form.append("t", type);
				form.append("v", value);
				const update = await Axios.post(
					`${process.env.REACT_APP_API_URL}change_notifs`, form);
				return update.data;
			} catch (e) {
				console.log(e);
			}
		}

		return false;

	};

	setVideoRating = async (videoId, votes) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append("key", loginToken);
				form.append("n", votes);
				form.append("v", videoId);
				const update = await Axios.post(`${process.env.REACT_APP_API_URL}setvideorating`, form);
				return update.data;
			} catch (e) {
				console.log(e)
			}
		}
		return false;
	};

	addToVideoFavorite = async (videoId) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append("key", loginToken);
				form.append("v", videoId);
				const update = await Axios.post(`${process.env.REACT_APP_API_URL}videofav`, form);
				return update.data;
			} catch (e) {
				console.log(e)
			}
		}
		return false;
	};


	leavePrivatePlaylists = async (playlistId) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append("key", loginToken);
				form.append("i", playlistId);
				const update = await Axios.post(`${process.env.REACT_APP_API_URL}leavePrivatePlaylists`, form);
				return update.data;
			} catch (e) {
				console.log(e)
			}
		}
		return false;
	};

	addStatisticRecord = async (videoId = null, liveId = null, resolution = null) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken || (videoId !== null || liveId !== null)) {
			try {
				let form = new FormData();
				form.append("key", loginToken);
				if (videoId !== null) {
					form.append("v", videoId);
				} else if (liveId !== null) {
					form.append("l", liveId);
				}

				if (resolution !== null) {
					form.append("r", resolution)
				}

				const update = await Axios.post(`${process.env.REACT_APP_API_URL}rstats`, form);
				return update.data;
			} catch (e) {
				console.log(e)
			}
		}
		return false;
	};

	removeAccount = async (password) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let form = new FormData();
				form.append("key", loginToken);
				form.append("p", password);
				const update = await Axios.post(`${process.env.REACT_APP_API_URL}delacc`, form);
				return update.data;
			} catch (e) {
				console.log(e)
			}
		}
		return false;
	};


	//Stripe
	createPaymentIntent = async (clientSecret, paymentId, entityType, entityId) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken && clientSecret && entityType && entityId) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				formData.append("payment_method", paymentId);
				formData.append("entity_type", entityType);
				formData.append("entity_id", entityId);
				const update = await Axios.post(`${process.env.REACT_APP_API_STRIPE}createPaymentIntent`, formData);

				return update.data;

			} catch (e) {
				console.log(e);
			}
		}

		return false;
	};

	createSubscribe = async (clientSecret, priceSecret) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken && clientSecret && priceSecret) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				formData.append("priceSecret", priceSecret);
				const update = await Axios.post(`${process.env.REACT_APP_API_STRIPE}createSubscribe`, formData);

				return update.data;

			} catch (e) {
				console.log(e);
			}
		}

		return false;
	};

	cancelSubscription = async (clientSecret, priceId) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken && clientSecret) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				formData.append("price", priceId);
				const update = await Axios.post(`${process.env.REACT_APP_API_STRIPE}cancelSubscription`, formData);

				return update.data;

			} catch (e) {
				console.log(e);
			}
		}

		return false;
	};

	updateStripeCustomer = async (clientSecret, user) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("user", JSON.stringify(user));
				formData.append("client", clientSecret);
				const update = await Axios.post(`${process.env.REACT_APP_API_STRIPE}updateStripeCustomer`, formData);
				return update.data;

			} catch (e) {
				console.log(e);
			}
		}

		return false;
	};


	attachPaymentMethod = async (clientSecret, source) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				formData.append("source", source);
				const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}attachPaymentMethod`, formData);
				return result.data;

			} catch (e) {
				console.log(e);
			}
		}

		return false;
	};

	detachPaymentMethod = async (clientSecret, source) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				formData.append("source", source);
				const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}detachPaymentMethod`, formData);
				return result.data;

			} catch (e) {
				console.log(e);
			}
		}

		return false;
	};

	updateDefaultPaymentMethod = async (clientSecret, source) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			try {
				let formData = new FormData();
				formData.append("key", loginToken);
				formData.append("client", clientSecret);
				formData.append("source", source);
				const result = await Axios.post(`${process.env.REACT_APP_API_STRIPE}updateDefaultPaymentMethod`, formData);
				return result.data;

			} catch (e) {
				console.log(e);
			}
		}

		return false;
	};

	render() {
		const contextValue = {
			rootState: this.state,

			isLoggedIn: this.isLoggedIn,
			isLoginAuth: this.isLoginAuth,
			registerUser: this.registerUser,
			loginUser: this.loginUser,
			logoutUser: this.logoutUser,
			updateUserData: this.updateUserData,
			updateAvatar: this.updateAvatar,
			removeAccount: this.removeAccount,
			updatePassword: this.updatePassword,
			updateUserNotif: this.updateUserNotif,
			toggleFollow: this.toggleFollow,
			updateEmail: this.updateEmail,
			setVideoRating: this.setVideoRating,
			addToVideoFavorite: this.addToVideoFavorite,
			addStatisticRecord: this.addStatisticRecord,
			createPaymentIntent: this.createPaymentIntent,
			createSubscribe: this.createSubscribe,
			cancelSubscription: this.cancelSubscription,
			updateStripeCustomer: this.updateStripeCustomer,
			attachPaymentMethod: this.attachPaymentMethod,
			detachPaymentMethod: this.detachPaymentMethod,
			updateDefaultPaymentMethod: this.updateDefaultPaymentMethod,
			leavePrivatePlaylists: this.leavePrivatePlaylists
		};
		return (
			<MyContext.Provider value={contextValue}>
				{this.props.children}
			</MyContext.Provider>
		);
	}
}

export default MyContextProvider;
