import React, {Fragment, useContext, useEffect, useState} from "react";
import {isEmpty} from "../../../Utils";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import axios from "axios";
import { MyContext } from "../../../../contexts/MyContext";
import {toggleModal} from "../../../../actions/modal.action";
import {getCustomer} from "../../../../actions/stripe.action";

const EditBillingInfo = ({onClose}) => {
    const dispatch = useDispatch();
    const customer = useSelector((state) => state.stripeReducer.getCustomerReducer)
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    const { updateStripeCustomer } = useContext(MyContext);
    const [countries, setCountries] = useState([]);
    const [defaultCountry, setDefaulCountry] = useState({});
    const [errorName, setErrorName] = useState(null);
    const [errorCountry, setErrorCountry] = useState(null);
    const [errorCity, setErrorCity] = useState(null);
    const [errorRegion, setErrorRegion] = useState(null);
    const [errorStreet, setErrorStreet] = useState(null);
    const [errorHouse, setErrorHouse] = useState(null);
    const [errorPostalCode, setErrorPostalCode] = useState(null);
    const [canUpdate, setCanUpdate] = useState(false);
    const [customerData, setCustomerData] = useState({
        name: customer.shipping?.name,
        country: customer.shipping?.address.country,
        city: customer.shipping?.address.city,
        region: customer.shipping?.address.state,
        street: customer.shipping?.address.line1,
        house: customer.shipping?.address.line2,
        postal_code: customer.shipping?.address.postal_code
    });

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor: "pointer",
            borderRadius: "5px",
            border: "1px solid #8d8d8d",
        }),
        option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
            ...provided,
            color: isDisabled ? undefined : isSelected ? "#ffffff" : isFocused ? "black" : undefined,
            cursor: "pointer",
            backgroundColor: isDisabled ? undefined : isSelected ? "#49546c" : isFocused ? "white" : undefined,
            ":hover": {
                ...provided[":hover"],
                color: isSelected ? "#ffffff" : "black"
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderStyle: "none",
            borderWidth: "unset",
            minHeight: "2.5rem",
            color: "#ffffff",
            boxShadow: "none",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#000000",
            fontSize: "1.6rem"
        }),
        menu: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            backgroundColor: "#1b283c",
            marginTop: 0,
            borderRadius: "0 0 4px 4px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    };

    useEffect(() => {
      axios.get("https://countriesnow.space/api/v0.1/countries/iso")
          .then((result) => {
              if (result.status === 200) {
                  const newArrayOfObj = result.data.data.map(
                      ({name: label, Iso2: value, ...rest}) => ({label, value, ...rest}));
                  setCountries(newArrayOfObj)
              }
          })
          .catch((error) => console.log(error))
    }, []);

    useEffect(() => {
        if (!isEmpty(customerData.name)) {
            if (customerData.name.length < 2 || !customerData.name.match(/^[a-zA-ZÀ-ÿ\s-]*$/)) {
                setErrorName(true);
            } else {
                setErrorName(false)
            }
        }
        if (!isEmpty(customerData.country)) {
            if (customerData.country.length < 2) {
                setErrorCountry(true);
            } else {
                setErrorCountry(false);
            }
        }
        if (!isEmpty(customerData.city)) {
            if (customerData.city.length < 2 || !customerData.city.match(/^[a-zA-ZÀ-ÿ\s-]*$/)) {
                setErrorCity(true);
            } else {
                setErrorCity(false)
            }
        }
        if (!isEmpty(customerData.region)) {
            if (customerData.region.length < 2 || !customerData.region.match(/^[a-zA-ZÀ-ÿ\s-]*$/)) {
                setErrorRegion(true);
            } else {
                setErrorRegion(false)
            }
        }
        if (!isEmpty(customerData.street)) {
            if (customerData.street.length < 2) {
                setErrorStreet(true);
            } else {
                setErrorStreet(false)
            }
        }
        if (!isEmpty(customerData.house)) {
            if (customerData.house.length < 1) {
                setErrorHouse(true);
            } else {
                setErrorHouse(false)
            }
        }
        if (!isEmpty(customerData.postal_code)) {
            if (customerData.postal_code.length < 1 || !customerData.postal_code.match(/^[0-9_]*$/)) {
                setErrorPostalCode(true);
            } else {
                setErrorPostalCode(false)
            }
        }
    }, [customerData])

    useEffect(() => {
        if (errorName === false && errorCountry === false && errorCity === false && errorRegion === false && errorStreet === false && errorHouse === false && errorPostalCode === false) {
            setCanUpdate(true)
        } else {
            setCanUpdate(false)
        }
    }, [errorName, errorCountry, errorCity, errorRegion, errorStreet, errorHouse, errorPostalCode]);

    useEffect(() => {
        countries.forEach((item) => {
            if (item.value === customerData.country) {
                setDefaulCountry(item)
            }
        })
    }, [countries, customerData.country]);

    const updateCustomer = async () => {

        if (!canUpdate) {
            dispatch(toggleModal({opened: true, success: false, text: "Fill in all the fields"}));
            return;
        }

        await updateStripeCustomer(customer.id, customerData).then((result) => {
            if(result.result === "ok") {
                dispatch(getCustomer());
                onClose(true);
                dispatch(toggleModal({opened: true, success: true, text: "Customer data updated"}));
            } else if (result.result === "bad" && result.error) {
                dispatch(toggleModal({opened: true, success: false, text: result.error}));
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    return (
        <Fragment>
            <div className="background position-fixed top-left bottom-right" style={{backgroundColor: "rgb(0 0 0 / 51%)"}}/>
            <div className="absolute-center modal p-4">
                <h3>Edit the payment method</h3>
                <div className={`input mt-4 mb-0 ${errorName ? "error" : ""}`}>
                    <label htmlFor="name">Full Name*</label>
                    <input type="text" id="name" className="border" defaultValue={customerData.name} onChange={(e) => setCustomerData(prevState => ({...prevState, name: e.target.value}))}/>
                </div>
                <div className="d-grid">

                    <div className={`input mt-2 mb-0 ${errorCountry ? "error" : ""}`}>
                        <label htmlFor="country">Country*</label>
                        <Select
                            id="language"
                            styles={customStyles}
                            value={defaultCountry}
                            options={countries}
                            onChange={(option) => setCustomerData(prevState => ({...prevState, country: option.value}))}
                        />
                    </div>

                    <div className={`input mt-2 mb-0 ${errorCity ? "error" : ""}`}>
                        <label htmlFor="city">City*</label>
                        <input type="text" id="city" className="border" defaultValue={customerData.city} onChange={(e) => setCustomerData(prevState => ({...prevState, city: e.target.value}))}/>
                    </div>
                </div>
                <div className="d-grid">
                    <div className={`input mt-2 mb-0 ${errorRegion ? "error" : ""}`}>
                        <label htmlFor="region">Province or region*</label>
                        <input type="text" id="region" className="border" defaultValue={customerData.region} onChange={(e) => setCustomerData(prevState => ({...prevState, region: e.target.value}))}/>
                    </div>
                    <div className={`input mt-2 mb-0 ${errorStreet ? "error" : ""}`}>
                        <label htmlFor="street">Street*</label>
                        <input type="text" id="street" className="border" defaultValue={customerData.street} onChange={(e) => setCustomerData(prevState => ({...prevState, street: e.target.value}))}/>
                    </div>
                </div>
                <div className="d-grid">
                    <div className={`input mt-2 mb-0 ${errorHouse ? "error" : ""}`}>
                        <label htmlFor="number">Apartment or house*</label>
                        <input type="text" id="number" className="border" defaultValue={customerData.house} onChange={(e) => setCustomerData(prevState => ({...prevState, house: e.target.value}))}/>
                    </div>
                    <div className={`input mt-2 mb-0 ${errorPostalCode ? "error" : ""}`}>
                        <label htmlFor="post_code">Postal code*</label>
                        <input type="text" id="post_code" className="border" defaultValue={customerData.postal_code} onChange={(e) => setCustomerData(prevState => ({...prevState, postal_code: e.target.value}))}/>
                    </div>
                </div>

                <div className="d-flex float-right mt-4">
                    <button className="btn btn-stripe mr-3"
                            style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                            onClick={() => onClose(true)}
                    >Cancel</button>

                    <button className="btn btn-stripe"
                            disabled={!canUpdate}
                            style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                            onClick={updateCustomer}
                    >Update</button>
                </div>

            </div>
        </Fragment>
    );
};

export default EditBillingInfo;