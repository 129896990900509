import videojs from 'video.js';
import {preloadImage} from "../../components/Utils";

const Plugin = videojs.getPlugin('plugin');

class Sponsors extends Plugin {
    constructor(player, options) {
        super(player, options);
        //this.on(player, ['playing', 'pause'], this.updateState);
        //this.state = options;
        //this.on("statechanged", this.preRollStart);
        this.videoEl = player.el();
        this.on("statechanged", this.prepareSponsor);
        this.default = {
            background: null,
            headings: null,
            logos: null,
            sponsors: null,
            displayTime: 5
        };
        this.timeout = null;
        this.setState(this.default);
    }

    dispose() {
        super.dispose();
        clearTimeout(this.timeout);
        this.off("statechanged", this.prepareSponsor);
    }

    sponsorUpdate = (sponsors) => {
        this.setState(sponsors);
    };

    prepareSponsor() {

        /*if (this.state.background === null) {
            this.reset();
            return;
        }*/

        if (this.divElem) {
            this.divElem.remove();
        }

        this.player.removeClass('vjs-watermark');

        this.divElem = document.createElement("div");

        document.querySelector(".back").classList.add("hidden");
        this.divElem.classList.add("vjs-sponsors-content");

        if (this.state.background) {
            let background = document.createElement("div");
            const img = preloadImage(this.state.background);
            background.classList.add("background");
            background.appendChild(img);
            this.divElem.appendChild(background);
        }

        let centerDiv = document.createElement("div");
        centerDiv.classList.add("center");

        if (this.state.headings) {
            let headingDiv = document.createElement("div");
            headingDiv.classList.add("heading");
            if (this.state.headings.first) {
                let headerFirst = document.createElement("h2");
                headerFirst.textContent = `${this.state.headings.first}`;
                headingDiv.appendChild(headerFirst);
            }

            if (this.state.headings.second) {
                let headerSecond = document.createElement("h3");
                headerSecond.textContent = `${this.state.headings.second}`;
                headingDiv.appendChild(headerSecond);
            }
            centerDiv.appendChild(headingDiv);
        }

        if (this.state.sps) {

            if (this.state.sps.primary) {
                let sponsorPrimary = document.createElement("div");
                sponsorPrimary.classList.add("sp1");
                for (let key in this.state.sps.primary) {
                    if (this.state.sps.primary.hasOwnProperty(key)) {
                        const divImg = document.createElement("div");
                        const img = preloadImage(this.state.sps.primary[key]);
                        divImg.classList.add("wrp");
                        divImg.appendChild(img);
                        sponsorPrimary.appendChild(divImg);
                    }
                }
                centerDiv.appendChild(sponsorPrimary);
            }

            if (this.state.sps.secondary) {
                let sponsorSecondary = document.createElement("div");
                sponsorSecondary.classList.add("sp2");
                for (let key in this.state.sps.secondary) {
                    if (this.state.sps.secondary.hasOwnProperty(key)) {
                        const divImg = document.createElement("div");
                        const img = preloadImage(this.state.sps.secondary[key]);
                        divImg.classList.add("wrp");
                        divImg.appendChild(img);
                        sponsorSecondary.appendChild(divImg);
                    }
                }
                centerDiv.appendChild(sponsorSecondary);
            }

            if (this.state.sps.third) {
                let sponsorThird = document.createElement("div");
                sponsorThird.classList.add("sp3");
                for (let key in this.state.sps.third) {
                    if (this.state.sps.third.hasOwnProperty(key)) {
                        const divImg = document.createElement("div");
                        const img = preloadImage(this.state.sps.third[key]);
                        divImg.classList.add("wrp");
                        divImg.appendChild(img);
                        sponsorThird.appendChild(divImg);
                    }
                }
                centerDiv.appendChild(sponsorThird);
            }
        }

        this.divElem.appendChild(centerDiv);

        if (this.state.logos) {

            if (this.state.logos.top) {
                let topLogo = document.createElement("img");
                topLogo.src = `${this.state.logos.top}`;

                if (this.state.logos.positions.first) {
                    topLogo.classList.add(`${this.state.logos.positions.first}`, "logos")
                } else {
                    topLogo.classList.add("top-left", "logos");
                }

                this.divElem.appendChild(topLogo);
            }

            if (this.state.logos.bottom) {
                let bottomLogo = document.createElement("img");
                bottomLogo.src = `${this.state.logos.bottom}`;

                if (this.state.logos.positions.second) {
                    bottomLogo.classList.add(`${this.state.logos.positions.second}`, "logos")
                } else {
                    bottomLogo.classList.add("top-left", "logos");
                }

                this.divElem.appendChild(bottomLogo);
            }

        }

        this.player.trigger('sponsorsready');
    }

    startSponsors () {
        this.videoEl.appendChild(this.divElem);
        this.timeout = setTimeout(() => {
            this.stopShow();
        }, this.state.displayTime * 1000);
    };

    sponsorsStop() {
        this.stopShow();
    };

    stopShow() {
        this.player.trigger("sponsorstop");
        this.player.sponsorsFinished = true;
        this.divElem.remove();
        if (document.querySelector(".back").classList.contains("hidden")) {
            document.querySelector(".back").classList.remove("hidden");
        }
    };

    reset() {
        this.setState(this.default);
        if (this.divElem) {
            this.divElem.remove();
        }
        this.player.removeClass('vjs-watermark');
    }
}
videojs.registerPlugin('sponsors', Sponsors);
