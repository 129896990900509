import React, {Fragment} from "react";
import {Redirect} from "react-router-dom";

const PrivacyPolicy = () => {
    if (process.env.REACT_APP_SHOW_POLICY === "yes") {
        return (
            <Fragment>
                {process.env.REACT_APP_PROJECT_CODE === "cyclism" &&
                    <main className="policy-page">
                        <div className="policy-text">
                            <h2>Conditions Générales d'Utilisation</h2>
                            <h3>Préambule</h3>
                            <p>
                                L'Application Velo+TV est l'application officielle de la Fédération Française de Cyclisme.
                                L'Application est gratuite, accessible au grand public,
                                et a pour objet la diffusion de l'actualité cyclisme régie par la Fédération Française de
                                Cyclisme, des utilisateurs licenciés ou non, pratiquants ou non-pratiquants quels que soient
                                leur âge, leur condition physique et leur objectif.<br/>
                                L'accès et l'utilisation à cette application sont soumis aux conditions décrites ci-dessous
                                ainsi qu'aux lois et/ou règlements applicables.<br/>
                                La connexion et l'accès à l'Application impliquent une acceptation intégrale de l'utilisateur de
                                toutes les dispositions inscrites et des Conditions Générales d'Utilisation.
                                On désigne par « Utilisateur » toute personne qui utilise l'Application via un smartphone, une
                                tablette ou un ordinateur.<br/>
                                L'association Fédération Française de Cyclisme se réserve le droit de modifier unilatéralement
                                et sans préavis les présentes mentions légales, les Conditions Générales d'Utilisation et tous
                                les éléments, produits ou services présentés sur l'Application « Velo+Tv ».<br/>
                                <br/>
                                A chaque connexion, les utilisateurs se doivent de consulter les Conditions Générales
                                d'Utilisation en cas de toute modification de la part de MyTVchain <br/>
                                La présente application est développée par : MyTVchain / FFC.<br/> Les contenus sont alimentés
                                par : FFC ou Fédération Française de Cyclisme <br/> Copyright : © FFC <br/>
                                La société MyTVchain, SAS au capital de 10,000€, dont le siège social est situé <u>1501 RTE DES
                                DOLINES 06560 VALBONNE</u>, immatriculée au Registre du Commerce et des Sociétés de Grasse sous
                                le numéro Grasse B 842 064 222
                                (numéro de Siret : 84206422200016 - code APE : ), représentée par Monsieur Xavier GESNOUIN,
                                agissant en sa qualité de Président.<br/>
                                <br/>
                                Ci-après dénommée « MyTVchain »<br/>ET <br/> L'Association FEDERATION FRANCAISE DE CYCLISME
                                (FFC), dont le siège social est située au 1, rue Laurent Fignon - CS 40100 - Montigny le
                                Bretonneux - 78069 SAINT QUENTIN EN YVELINES,
                                SIRET N° 784 448 763 0036, représentée par Monsieur Michel CALLOT, Président et ayant tous les
                                pouvoirs à cet effet. Ci-après dénommée « FFC »
                            </p>
                            <h3>Propriété intellectuelle</h3>
                            <p>
                                "L'association Fédération Française de Cyclisme avise les utilisateurs de l'Application «
                                Velo+tv » que les éléments inscrits ci-dessus : <br/>
                                Sont protégés par la loi n°78-17 du 6 janvier 1978 relative aux dispositions de la CNIL en
                                France (Commission Nationale Informatiques et Libertés) Sont protégés par la législation sur les
                                marques.<br/>
                                Quelle que soit leur nature (brevet, nom, logo, dessin, base de données, fichiers, séquences
                                vidéos...), tous les droits présents dans l'Application sont la propriété intellectuelle de
                                Fédération Française de Cyclisme. Les marques ou raisons sociales citées au sein de
                                l'Application sont strictement protégées dans le cadre du code de la propriété
                                intellectuelle.<br/>
                                L'Utilisateur s'engage à une utilisation des contenus de l'Application dans un cadre strictement
                                privé. Toute reproduction, utilisation, modification, commercialisation partielle ou intégrale
                                sous quelque forme, par quelque procédé et sur quelque support que soit (papier ou numérique)
                                sont interdites. <br/>
                                L'Utilisateur devra solliciter l'autorisation préalable de la Fédération Française de Cyclisme
                                pour toute reproduction, publication, copie des différents contenus, sous peine de contrefaçon
                                susceptible d'entraîner une peine d'emprisonnement et une amende substantielle.".
                            </p>
                            <h3>Responsabilité</h3>
                            <p>
                                Les sources des informations diffusées sur la Plateforme sont réputées fiables. Toutefois,
                                MyTVchain se réserve le droit d'une non-garantie sur l'exhaustivité du contenu informationnel
                                proposé.<br/>
                                Ainsi, l'Utilisateur assume seul l'entière responsabilité de l'utilisation des informations et
                                contenus de l'Application et ne saurait en aucune façon engager la responsabilité de la
                                Fédération Française de Cyclisme ou de MyTVchain. Le calcul des données de calories brulées et
                                celui du dénivelé est uniquement à titre indicatif elle n'ont aucune valeure scientifique.<br/>
                                La responsabilité de la Fédération Française de Cyclisme ainsi que celle de MyTVchain ne peut
                                être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un problème
                                tiers tel que : <br/>
                                tout type virus ou de bug quelle qu'en soit la forme <br/>
                                tout programme ou application qui serait incompatible avec l'infrastructure utilisée par
                                l'utilisateur.<br/>
                                tout type de panne, interruption ou erreur, évolution ou remise en état<br/>
                                tous réseaux ou services liés, surcharge, négligence ou faute de tiers ou de l'utilisateur<br/>
                                tous cas d'évènements indépendants de la volonté de la présente Application
                            </p>
                            <h3>Limite de responsabilité</h3>
                            <p>
                                "L'Utilisateur utilise la présente Application smartphone à ses risques et périls.
                                L'association Fédération Française de Cyclisme ainsi que MyTVchain ne pourra être tenue pour
                                responsable de tout dommage direct ou indirect, y compris la perte de données,
                                de bénéfices et d'interruption d'activité, résultant d'une visite sur l'Application, de
                                l'utilisation ou de l'impossibilité d'utiliser le contenu de cette Application."</p>
                            <h3>Informations à caractère personnel</h3>
                            <p>
                                Les données personnelles de l'Utilisateur réunies via l'Application n'ont d'autres destinataires
                                que MyTVchain et la Fédération Française de Cyclisme.<br/>
                                L'Application assure à l'Utilisateur une collecte et un traitement d'informations personnelles
                                dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative aux
                                dispositions de la CNIL en France (Commission Nationale Informatiques et Libertés).<br/>
                                En vertu des articles 39 et 40 de la loi en date du 6 janvier 1978, l'Utilisateur dispose d'un
                                droit d'accès, de rectification, de suppression et d'opposition de ses données
                                personnelles.<br/>
                                Conformément au décret n°2007-451 du 25 mars 2007, le traitement de la demande peut être, selon
                                sa nature, subordonné à la production préalable de la copie d'une pièce d'identité en cours de
                                validité. Pour plus de détails, se référer aux Conditions Générales d'Utilisation.<br/>
                                Les utilisateurs sont tenus de respecter les dispositions de la loi informatique et libertés
                                mentionnée ci-dessus, dont la violation est passible de sanctions pénales. Ils doivent notamment
                                s'abstenir, s'agissant des données à caractère personnel auxquelles ils pourraient avoir accès,
                                de toute collecte, de toute utilisation détournée et, d'une manière générale, de tout acte
                                susceptible de porter atteinte à la vie privée ou à la réputation des personnes.
                            </p>
                            <h3>Collecte d'informations personnelles</h3>
                            <p>
                                Nous recueillons des informations telles que votre nom, votre adresse e-mail, votre
                                image et votre identifiant utilisateur lorsque vous nous les fournissez directement, par
                                exemple, lors de votre inscription sur notre site web, en remplissant un formulaire de
                                contact ou en soumettant une demande de support. Nous pouvons également recueillir des
                                images que vous téléchargez sur notre site web ou fournissez via notre logiciel.
                                <br/>
                                Ces informations sont utilisées pour vous fournir l'accès à nos services, traiter vos
                                demandes et fournir un support. Nous pouvons également utiliser votre image conformément
                                à vos préférences et paramètres de compte.
                                <br/>
                                L'identifiant utilisateur est utilisé pour une identification unique au sein de notre
                                système et pour assurer la sécurité de votre compte. Nous nous efforçons de garantir la
                                sécurité et la confidentialité de vos informations et images, et ne les partageons pas
                                avec des tiers sans votre consentement, sauf lorsque cela est nécessaire pour vous
                                fournir les services demandés ou pour respecter la loi applicable.
                            </p>
                            <h3>Droit applicable</h3>
                            <p>
                                La présente Plateforme « Velo+TV » et ses mentions légales sont soumises au droit français.<br/>
                                Pour plus de détails, se référer aux Conditions Générales d'Utilisation.
                            </p>
                        </div>
                    </main>
                }

                {process.env.REACT_APP_PROJECT_CODE === "tcr" &&
                    <main className="policy-page">
                        <div className="policy-text">
                            <h2>General Terms and Conditions of Use</h2>
                            <h3>Prelude</h3>
                            <p style={{marginBottom: "8px"}}>
                                The TCR TVPlatform is the official OTT platform of the World Sporting Consulting Ltd. The Platform is accessible to the general public, and aims at broadcasting content managed and owned by the World Sporting Consulting Ltd. The access to and use of this platform are subject to the conditions described below as well as to applicable laws and/or regulations.<br/>
                            </p>
                            <p style={{marginBottom: "8px"}}>
                                Logging in and accessing the Platform implies full acceptance by the user of all the provisions and the General Conditions of Use.  “User" is defined as any person who uses the Platform via a smartphone, tablet or computer.
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                World Sporting Consulting Ltd reserves the right to unilaterally modify without prior notice the present legal notice, the General Conditions of Use and all the elements, products or services presented on the "TCR TV " Platform.
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                At each connection, users must consult the General Terms of Use in case of any modification by MyTVchain.
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                This Platform is developed by: MyTVchain
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                The contents are powered by: WSC or World Sporting Consulting Ltd
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                Copyright : © WSC
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                The company MyTVchain, SAS with a capital of 10,000€, whose head office is located 1501 RTE DES DOLINES 06560 VALBONNE, registered with the Grasse Trade and Companies,  Registered under the number Grasse B 842 064 222. (Siret number: 84206422200016 - APE code: ), represented by Mr. Xavier GESNOUIN, acting in his capacity as President.
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                Hereinafter referred to as "MyTVchain".
                            </p>
                            <p style={{marginBottom: "8px"}}>
                                AND
                            </p>
                            <p>
                                The World Sporting Consulting Ltd (WSC), whose head office is located at 17 Carlisle Street, 1st Floor, London W1D 3BU, London EC3M 8AA, United Kingdom, represented by Mr. Marcello Lotti, President and having all the powers for this purpose. Hereinafter referred to as "WSC".
                            </p>
                            <h3>Intellectual property</h3>
                            <p style={{marginBottom: "8px"}}>
                                "WSC hereby advises users of the "TCR.TV" Apps & platform that the following elements listed above:
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                Are protected by the law n°78-17 of January 6, 1978 relating to the provisions of the French DPA in France (French Data Protection Authority) Are protected by the legislation on trademarks.
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                Whatever their nature (patent, name, logo, drawing, database, files, video sequences...), all the rights present in the Platform are the intellectual property of WSC. The brands or company names mentioned in the Platform are strictly protected under the code of intellectual property.
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                The User agrees to use the content of the Platform in a strictly private context. Any reproduction, use, modification, partial or complete commercialization in any form, by any process and on any medium whatsoever (paper or digital) are prohibited.
                            </p>
                            <p>

                                The User must request prior authorization from WSC for any reproduction, publication, or copy of the various contents, under penalty of infringement likely to result in a penalty of imprisonment and a substantial fine".
                            </p>
                            <h3>Responsibility</h3>
                            <p style={{marginBottom: "8px"}}>
                                The sources of the information published on the Platform & apps are deemed reliable. However, MyTVchain reserves the right not to guarantee the completeness of the proposed information content.
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                Thus, the User assumes sole responsibility for the use of the information and content of the Platform and shall not in any way engage the responsibility of WSC or of MyTVchain.
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                WSC and MyTVchain shall not be held liable for in case of majeure force or of unforeseeable and insurmountable fact of a third party problem such as :
                            </p>
                            <p>
                                <ul>
                                    <li> - any type of virus or bug whatever its form</li>
                                    <li> - any program or application that is incompatible with the infrastructure used by the user</li>
                                    <li> - any type of failure, interruption or error, evolution or restoration</li>
                                    <li> - any network or related services, overload, negligence or fault of third parties or the user</li>
                                    <li> - any event beyond the control of this Platform</li>
                                </ul>
                            </p>
                            <h3>Limitation of liability</h3>
                            <p>
                                The User uses the Web Site and / or the App at his own risk.<br/>
                                WSC and MyTVchain cannot be held responsible for any direct or indirect damage, including loss of data, profits and business interruption, resulting from a visit to the site, from the use or the impossibility to use the content of this App.
                            </p>
                            <h3>Personal information</h3>
                            <p style={{marginBottom: "8px"}}>
                                The User's personal data collected through the Platform are only intended for MyTVchain and WSC.
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                The Platform ensures that the User's personal information is collected and processed with respect to privacy in accordance with law n°78-17 of 6 January 1978 relating to the provisions of the French DPA in France (French Data Protection Authority).
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                In accordance with articles 39 and 40 of the law dated January 6, 1978, the User has the right to access, rectify, delete and opposition of his personal data.
                            </p>
                            <p style={{marginBottom: "8px"}}>

                                In accordance with the decree n°2007-451 of March 25, 2007, the treatment of the request can be, according to its nature, subject to the prior production of a copy of a valid identity document. For more details, please refer to the General Terms of Use.
                            </p>
                            <p>

                                Users are required to comply with the provisions of the Data Protection Act mentioned above, whose violation is liable to criminal sanctions. In particular, they must refrain, with regard to the personal data to which they may have access, from any collection, any misuse and, generally speaking, from any act likely to undermine the private life or reputation of individuals.

                            </p>

                            <h3>Collecte d'informations personnelles</h3>
                            <p style={{marginBottom: "8px"}}>
                                Nous recueillons des informations telles que votre nom, votre adresse e-mail, votre
                                image et votre identifiant utilisateur lorsque vous nous les fournissez directement, par
                                exemple, lors de votre inscription sur notre site web, en remplissant un formulaire de
                                contact ou en soumettant une demande de support. Nous pouvons également recueillir des
                                images que vous téléchargez sur notre site web ou fournissez via notre logiciel.
                            </p>
                            <p style={{marginBottom: "8px"}}>
                                Ces informations sont utilisées pour vous fournir l'accès à nos services, traiter vos
                                demandes et fournir un support. Nous pouvons également utiliser votre image conformément
                                à vos préférences et paramètres de compte.
                            </p>
                            <p>
                                L'identifiant utilisateur est utilisé pour une identification unique au sein de notre
                                système et pour assurer la sécurité de votre compte. Nous nous efforçons de garantir la
                                sécurité et la confidentialité de vos informations et images, et ne les partageons pas
                                avec des tiers sans votre consentement, sauf lorsque cela est nécessaire pour vous
                                fournir les services demandés ou pour respecter la loi applicable.
                            </p>

                            <h3>Applicable law</h3>
                            <p style={{marginBottom: "8px"}}>
                                The present " TCR TV” Platform and its legal notice are subject to UK law.
                            </p>
                            <p style={{marginBottom: "8px"}}>
                                For more details, please refer to the General Terms of Use.
                            </p>
                            <p>
                                All material is copyright WSC unless otherwise specified. The applicable law for the copyright material is subject to the Swiss law.
                            </p>
                        </div>
                    </main>
                }
            </Fragment>
        )
    } else {
        return  <Redirect to="/" />;
    }
};

export default PrivacyPolicy;
