import { combineReducers } from "redux";
import {
	GET_FILTER
} from "../actions/filter.action";

const initialState = [];

function getFilterReducer(state = initialState, action) {
	switch (action.type) {
		case GET_FILTER:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({ getFilterReducer });
