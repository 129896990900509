import React, {useContext, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import {isEmpty} from "../Utils";
import {MyContext} from "../../contexts/MyContext";

const NavBarMobileEmail = (props) => {
    const setTypeMenu = props.setTypeMenu;
    const { rootState, updateEmail, logoutUser } = useContext(MyContext);
    const { theUser } = rootState;
    const [email, setEmail] = useState(null);
    const [errorMail, setErrorMail] = useState(false);
    const [allow, setAllow] = useState(false);
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translate
    );

    useEffect(() => {
        if (!isEmpty(theUser)) {
            setEmail(theUser.email);
        }
    }, [theUser]);

    const submitForm = async (event) => {
        event.preventDefault();
        if (allow) {
            const data = await updateEmail(email);
            setErrorMail(false);
            if (data.result === "ok") {
                logoutUser();
            } else {
                setErrorMail(true);
                document.querySelector("#email").parentElement.classList.add("error");
                console.log("erreur : " + data.message);
            }
        }
    };

    //HANDLE ERROR
    useEffect(() => {
        if (!isEmpty(email)) {
            email.length < 2 ||
            !email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
                ? document.querySelector("#email").parentElement.classList.add("error")
                : document.querySelector("#email").parentElement.classList.remove("error");
        }

    }, [email]);

    const submitHandle = () => {
        isEmpty(email) &&
        document.querySelector("#email").parentElement.classList.add("error");

        if (document.querySelectorAll(".input.error").length > 0) {
            setAllow(false);
            document.querySelectorAll(".input.error").forEach((elm) => {
                elm.classList.remove("error");
                setTimeout(() => {
                    elm.classList.add("error");
                }, 100);
            });
        } else {
            setAllow(true);
        }
    };

    return (
        <div className="parameters-container">
            <div className="parameters-title">
                <div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
                    <h1>
                        <span onClick={() => setTypeMenu("preference")}/>
                        {translations && !isEmpty(translations["register_email_ph"])
                            ? translations["register_email_ph"]
                            : "Email"
                        }
                    </h1>
                </div>
            </div>
            <div className="content">
                <div className="block">
                    <form onSubmit={submitForm} noValidate>
                        <div className="input">
                            <h4>
                                {translations && !isEmpty(translations["register_email_ph"])
                                    ? translations["register_email_ph"]
                                    : "Email"
                                }
                            </h4>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder={translations && !isEmpty(translations["register_email_ph"])
                                    ? translations["register_email_ph"]
                                    : "email"
                                }
                                required
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errorMail && (
                                <div className="errorDisplay">
                                    {translations && !isEmpty(translations["register_email_error"])
                                        ? translations["register_email_error"]
                                        : "Register email error"
                                    }
                                </div>
                            )}
                        </div>

                        <div className="submit">
                            <input
                                style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                                type="submit"
                                value={
                                    translations && !isEmpty(translations["button_update"])
                                        ? translations["button_update"]
                                        : "Update"
                                }
                                onClick={submitHandle}
                            />
                            <div id="validUpdate">
                                {translations && !isEmpty(translations["text_updated"])
                                    ? translations["text_updated"]
                                    : "The record has been updated"
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NavBarMobileEmail;
