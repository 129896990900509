import {
	GET_PLAYLIST,
	GET_PLAYLISTS, GET_POPULAR_VIDEOS_PLAYLIST, GET_PRIVATE_PLAYLISTS, GET_RECENT_VIDEOS_PLAYLIST
} from "../actions/playlist.action";
import { combineReducers } from "redux";

const initialState = [];

function playlistsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PLAYLISTS:
			return action.payload;
		default:
			return state;
	}
}

function playlistReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PLAYLIST:
			return action.payload;
		default:
			return state;
	}
}

function playlistsPopularVideoReducer(state = initialState, action) {
	switch (action.type) {
		case GET_POPULAR_VIDEOS_PLAYLIST:
			return action.payload;
		default:
			return state;
	}
}

function playlistsRecentVideoReducer(state = initialState, action) {
	switch (action.type) {
		case GET_RECENT_VIDEOS_PLAYLIST:
			return action.payload;
		default:
			return state;
	}
}

function privatePlaylistsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PRIVATE_PLAYLISTS:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({
	playlistReducer,
	playlistsReducer,
	playlistsPopularVideoReducer,
	playlistsRecentVideoReducer,
	privatePlaylistsReducer
});