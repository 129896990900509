import React, {Fragment, useEffect, useState} from "react";
import { useSelector} from "react-redux";
import {isEmpty} from "../Utils";
import moment from "moment";

const NavBarMobileSubscriptionSummary = (props) => {
    const setTypeMenu = props.setTypeMenu;
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const invoices = stripeReducer.getInvoicesReducer;
    const activePlan = stripeReducer.getActiveCustomerProductReducer;
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translate
    );
    const [customerPlan, setCustomerPlan] = useState(false);

    useEffect(() => {
        if (!isEmpty(activePlan)) {
            setCustomerPlan(activePlan)
        }
    }, [activePlan]);

    return (
        <div className="parameters-container">
            <div className="parameters-title">
                <div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
                    <h1>
                        <span onClick={() => setTypeMenu("subscription")}/>
                        {translations && !isEmpty(translations["summary"])
                            ? translations["summary"]
                            : "Summary"
                        }
                    </h1>
                </div>
            </div>
            <div className="content">
                <h4>
                    {translations && !isEmpty(translations["your_sub"])
                        ? translations["your_sub"]
                        : "Your subscription"
                    }
                </h4>
                <div className="summary">
                    {customerPlan ? (
                        <Fragment>
                            {customerPlan.plan_active === "1" ? (
                                <div style={{color: "green", textTransform: "capitalize"}}>Active plan</div>
                            ) : (
                                <div style={{color: "red", textTransform: "capitalize"}}>Not active plan</div>
                            )}
                            <div>
                                <span className="price">{(customerPlan.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})} / {customerPlan.type === "recurring" ? customerPlan.recurring.interval : "one-time"}</span>
                            </div>
                            <div>
                                <span className="name">{!isEmpty(customerPlan.product?.metadata?.title) ? customerPlan.product.metadata.title : ""}</span>
                            </div>
                        </Fragment>
                    ) : (
                        <div>
                            <span>You don't have an active subscribe.</span>
                        </div>
                    )}

                    <div className="submit">
                        <input
                            style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                            onClick={() => setTypeMenu("plans")}
                            type="submit"
                            value={
                                translations && !isEmpty(translations["move_next_level"])
                                    ? translations["move_next_level"]
                                    : "Move to the next level"
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="content">
                <h4>
                    {translations && !isEmpty(translations["next_debit"])
                        ? translations["next_debit"]
                        : "Next Direct Debit"
                    }
                </h4>
                <div className="summary">
                    {customerPlan && customerPlan.type === "recurring" && customerPlan.plan_active === "1" ? (
                        <Fragment>
                            <div>
                                <span className="price">{(customerPlan.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})} / {customerPlan.type === "recurring" ? customerPlan.recurring.interval : "one-time"}</span>
                            </div>
                            <div>
                                <span className="name">{moment.unix(customerPlan.next_direct).format("DD-MM-YYYY")}</span>
                            </div>
                        </Fragment>
                    ) : (
                        <div>
                            <span>You don't have next payments.</span>
                        </div>
                    )}
                    <div className="submit">
                        <input
                            style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                            onClick={() => setTypeMenu("payment")}
                            type="submit"
                            value={
                                translations && !isEmpty(translations["change_my_information"])
                                    ? translations["change_my_information"]
                                    : "Change my information"
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="content">
                <h4>
                    {translations && !isEmpty(translations["your_invoices"])
                        ? translations["your_invoices"]
                        : "Your Invoices"
                    }
                </h4>
                <div className="element invoices">
                    {invoices ? (
                        invoices.map((invoice) => {
                            return (
                                <Fragment key={invoice.id}>
                                    <div>
                                        <span>
                                            <img src="/img/icons/invoice.svg" alt="invoice"/>
                                        </span>
                                        <span className="no-wrap mx-1">
                                            {invoice?.status === "paid"
                                                ? moment.unix(invoice.status_transitions.paid_at).format("DD-MM-YYYY HH:mm")
                                                : (invoice?.status === "open" ? <a href={invoice.hosted_invoice_url} target='_blank' rel="noreferrer" style={{color: "black"}}>{invoice?.status}</a> : invoice?.status)
                                            }
                                        </span>
                                        <span className="download" onClick={() => window.open(invoice.invoice_pdf)}>
                                            <img src="/img/icons/download.svg" alt="download"/>
                                        </span>
                                    </div>
                                </Fragment>
                            )
                        })
                    ) : (
                        <div>
                            <span>You don't have an invoices.</span>
                        </div>
                    )}

                </div>  
            </div>
        </div>
    );
};

export default NavBarMobileSubscriptionSummary;
