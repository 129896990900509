import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {isEmpty} from "./Utils";
import moment from "moment";
import {useSelector} from "react-redux";

const Video = (props) => {
	const video = props.video;
	const handleFavorite = props.handleFavorite;
	const key = props.key;
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;
	const [styles, setStyles] = useState({});

	useEffect(() => {
		if (settings?.thumbnail_bg_color) {
			setStyles((prevState) => ({...prevState, backgroundColor: settings.thumbnail_bg_color}));
		}
		if (settings?.thumbnail_font_color) {
			setStyles((prevState) => ({...prevState, color: settings.thumbnail_font_color}));
		}
	}, [settings]);

	return (
		<div className="video" key={video.id}>
			{handleFavorite && (
				<span className="cross" onClick={() => handleFavorite(video.id, key)}>
					<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 5L5 9.5M5 5L9.5 9.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path d="M7.25 13.5C10.7018 13.5 13.5 10.7018 13.5 7.25C13.5 3.79822 10.7018 1 7.25 1C3.79822 1 1 3.79822 1 7.25C1 10.7018 3.79822 13.5 7.25 13.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>
					<span>
						{translations && !isEmpty(translations["remove_from_my_list"])
							? translations["remove_from_my_list"]
							: "Remove from my list"
						}
					</span>
				</span>
			)}

			<NavLink
				exact
				draggable="false"
				rel="noopener noreferrer"
				to={`/video/${video.id}`}
				onClick={() => window.scrollTo(0, 0)}
			>
				<img
					className="image"
					src={video.image_desktop}
					alt={`Apercu de ${video.name}`}
					width="100"
					height="66"
				/>

				<div className="title-container" style={styles}>
					<div className="play">
						<svg className="play_button" width="36" height="36" viewBox="0 0 36 36"
							 fill="none" xmlns="http://www.w3.org/2000/svg">
							<path className="round"
								  d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z"
								  fill="#362CAE"/>
							<path className="play_sign"
								  d="M24.8276 17.3793L13.6552 23.8297L13.6552 10.9289L24.8276 17.3793Z"
								  fill="white"/>
						</svg>
					</div>
					<div className="video-date">
						<div className="line">
							<svg className="bar" width="100" height="4" viewBox="0 0 100 4" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="100" height="4" rx="2" fill={settings?.thumbnail_font_color ? settings?.thumbnail_font_color : "#4E45AF"}/>
							</svg>
						</div>
						<div className="date">
							{video.addedon && moment(video.addedon).format("DD/MM/YYYY")}
						</div>
					</div>
					<h4>{video.name}</h4>
					<div className="video-description">
						{!isEmpty(video.descr) && video.descr.length > 180 ? video.descr.slice(0,180) + "..." : video.descr}
					</div>
				</div>
			</NavLink>
		</div>
	);
};

export default Video;
