import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTranslations } from "../../actions/translations.action";
import { isEmpty } from "../Utils";

const LanguagesSelection = () => {
	const dispatch = useDispatch();
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const selectedLanguage = translationsReducer.language;
	const [language, setLanguage] = useState(
		!isEmpty(localStorage.getItem("ottLang")) ? localStorage.getItem("ottLang").toUpperCase() : "EN"
	);
	const settings = useSelector(
		(state) => state.settingReducer.getSettingReducer.config
	);
	const [langImagePath, setLangImagePath] = useState("/img/icons/flag_of_en.svg");

	useEffect(() => {
		dispatch(getTranslations(language));
		// eslint-disable-next-line
	}, [language]);

	useEffect(() => {
		switch (selectedLanguage) {
			case "EN":
				setLangImagePath("/img/icons/flag_of_en.svg");
				break;
			case "FR":
				setLangImagePath("/img/icons/flag_of_fr.svg");
				break;
			case "ES":
				setLangImagePath("/img/icons/flag_of_es.svg");
				break;
			case "PT":
				setLangImagePath("/img/icons/flag_of_pt.svg");
				break;
			case "IT":
				setLangImagePath("/img/icons/flag_of_it.svg");
				break;
			case "DE":
				setLangImagePath("/img/icons/flag_of_de.svg");
				break;
			case "AR":
				setLangImagePath("/img/icons/flag_of_ar.svg");
				break;
			default:
				setLangImagePath("/img/icons/flag_of_en.svg");
		}
	}, [selectedLanguage]);

	return (
		<div
			className="languages"
		>
			<img
				src={langImagePath}
				alt={`flag ${selectedLanguage}`}
				width="20"
				height="20"
			/>
			<svg className="ml-1" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24">
				<path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" fill={settings?.menu_text_color ? settings.menu_text_color : "white"}/>
			</svg>
			<ul className="language-sub" style={{backgroundColor: settings?.topbar_bg_color ? settings.topbar_bg_color : "", color: settings?.menu_text_color ? settings.menu_text_color : ""}}>
				{settings?.available_languages.includes("en") && (
					<li
						className="d-flex"
						onClick={(e) => setLanguage("EN")}
					>
						<img
							src="/img/icons/flag_of_en.svg"
							alt="flag english"
							width="20"
							height="20"
						/>
						<span>English</span>
					</li>
				)}
				{settings?.available_languages.includes("fr") && (
					<li
						className="d-flex"
						onClick={(e) => setLanguage("FR")}
					>
						<img
							src="/img/icons/flag_of_fr.svg"
							alt="flag france"
							width="20"
							height="20"
						/>
						<span>Français</span>
					</li>
				)}
				{settings?.available_languages.includes("es") && (
					<li
						className="d-flex"
						onClick={(e) => setLanguage("ES")}
					>
						<img
							src="/img/icons/flag_of_es.svg"
							alt="flag spain"
							width="20"
							height="20"
						/>
						<span>Español</span>
					</li>
				)}
				{settings?.available_languages.includes("pt") && (
					<li
						className="d-flex"
						onClick={(e) => setLanguage("PT")}
					>
						<img
							src="/img/icons/flag_of_pt.svg"
							alt="flag Português"
							width="20"
							height="20"
						/>
						<span>Português</span>
					</li>
				)}
				{settings?.available_languages.includes("it") && (
					<li
						className="d-flex"
						onClick={(e) => setLanguage("IT")}
					>
						<img
							src="/img/icons/flag_of_it.svg"
							alt="flag italiano"
							width="20"
							height="20"
						/>
						<span>Italiano</span>
					</li>
				)}
				{settings?.available_languages.includes("de") && (
					<li
						className="d-flex"
						onClick={(e) => setLanguage("DE")}
					>
						<img
							src="/img/icons/flag_of_de.svg"
							alt="flag Deutsch"
							width="20"
							height="20"
						/>
						<span>Deutsch</span>
					</li>
				)}
				{settings?.available_languages.includes("ar") && (
					<li
						className="d-flex"
						onClick={(e) => setLanguage("AR")}
					>
						<img
							src="/img/icons/flag_of_ar.svg"
							alt="flag arabian"
							width="20"
							height="20"
						/>
						<span>عربي</span>
					</li>
				)}
			</ul>
		</div>
	);
};

export default LanguagesSelection;
