import React, { useContext} from "react";
import { useSelector } from "react-redux";
import {isEmpty} from "../Utils";
import {MyContext} from "../../contexts/MyContext";

const NavBarMobileParam = (props) => {
    const setTypeMenu = props.setTypeMenu;
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translate
    );

    const { rootState } = useContext(MyContext);
    const { theUser } = rootState;

    const { updateUserData } = useContext(MyContext);

    const submitForm = async (language) => {
        let userInfo = {
            username: theUser.username,
            lang: language
        };
        const data = await updateUserData(userInfo);
        if (data.result === "ok") {
            if (document.querySelector("#validUpdate")) {
                setTimeout(() => {
                    if (document.querySelector("#validUpdate")) {
                        document
                            .querySelector("#validUpdate")
                            .classList.add("display");
                    }
                }, 10);
                setTimeout(() => {
                    if (document.querySelector("#validUpdate")) {
                        document
                            .querySelector("#validUpdate")
                            .classList.remove("display");
                    }
                }, 2000);
            }
            console.log("updated");
        } else {
            console.log(data.message);
        }
    };

    return (
        <div className="parameters-container">
            <div className="parameters-title">
                <div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
                    <h1>
                        <span onClick={() => setTypeMenu("preference")}/>
                        {translations && !isEmpty(translations["languages"])
                            ? translations["languages"]
                            : "Languages"
                        }
                    </h1>
                </div>
            </div>
            <div className="content">
                <h4>
                    {translations && !isEmpty(translations["select_language"])
                        ? translations["select_language"]
                        : "Select language"
                    }
                </h4>
                <div className="select-block">
                    <select className="select" defaultValue={theUser.lang}
                            onChange={(e) => submitForm(e.target.value)}>
                        <option value="fr" selected={theUser.lang === "fr"}>Français</option>
                        <option value="en" selected={theUser.lang === "en"}>English</option>
                    </select>
                </div>
                <div id="validUpdate">
                    {translations && !isEmpty(translations["text_updated"])
                        ? translations["text_updated"]
                        : "The record has been updated"
                    }
                </div>
            </div>
        </div>
    );
};

export default NavBarMobileParam;
