import { combineReducers } from "redux";
import { GET_SEARCH } from "../actions/search.action";

const initialState = [];

function searchDataReducer(state = initialState, action) {
	switch (action.type) {
		case GET_SEARCH:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({ searchDataReducer });
