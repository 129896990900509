import React from "react";
import { useSelector} from "react-redux";
import moment from "moment";

const Invoice = () => {
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const invoices = stripeReducer.getInvoicesReducer;
    const paymentsIntents = stripeReducer.getPaymentsIntentsReducer;

    return (
        <div className="grid-invoices">
            <div>
                <h3>Invoices</h3>
                <div className="element invoices">
                    <table>
                        <thead>
                        <tr>
                            <th className="text-bold">Montant</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {invoices && (
                            invoices.map((invoice) => {
                                return (

                                    <tr key={invoice.id}>
                                        <td className="text-bold">{(invoice.total / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}</td>
                                        <td>{invoice.lines.data[0].description}</td>
                                        <td>
                                            {invoice?.status === "paid"
                                                ? moment.unix(invoice.status_transitions.paid_at).format("DD-MM-YYYY HH:mm")
                                                : (invoice?.status === "open" ? <a href={invoice.hosted_invoice_url} target='_blank' rel="noreferrer" style={{color: "black"}}>{invoice?.status}</a> : invoice?.status)
                                            }
                                        </td>
                                        <td className="download" onClick={() => window.open(invoice.invoice_pdf)}>
                                            <img src="/img/icons/download.svg" alt="download"/>
                                        </td>
                                    </tr>
                                )
                            })
                        )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="4">1-5 of 13 <span>left</span><span>right</span></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div>
                <h3>Payments</h3>
                <div className="element invoices">
                    <table>
                        <thead>
                        <tr>
                            <th className="text-bold">Montant</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {paymentsIntents && (
                            paymentsIntents.map((intent) => {
                                return (

                                    <tr key={intent.id}>
                                        <td className="text-bold">{(intent.amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}</td>
                                        <td>{intent.description}</td>
                                        <td>
                                            {intent?.status === "succeeded"
                                                ? moment.unix(intent.created).format("DD-MM-YYYY HH:mm")
                                                : (<a href={intent.hosted_invoice_url} target='_blank' rel="noreferrer" style={{color: "black"}}>{intent?.status}</a>)
                                            }
                                        </td>
                                        <td className="download" onClick={() => window.open(intent.invoice_pdf)}>
                                            <img src="/img/icons/download.svg" alt="download"/>
                                        </td>
                                    </tr>
                                )
                            })
                        )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="4">1-5 of 13 <span>left</span><span>right</span></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Invoice;