import axios from "axios";

export const GET_SEARCH = "GET_SEARCH";
export const getSearch = (input) => {
	return async (dispatch) => {
		let search = encodeURIComponent(input.toLowerCase());
		await axios.get(`${process.env.REACT_APP_API_URL}search&w=${search}&n=20`)
			.then((res) =>
				dispatch({ type: GET_SEARCH, payload: res.data })
			)
			.catch((error) => {
				console.log(error)
			});
	};
};
