import axios from "axios";
export const GET_DISCIPLINES = "GET_DISCIPLINES";

export const getDisciplines = (n = 10) => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getdisciplines&n=${n}`)
			.then((res) => dispatch({type: GET_DISCIPLINES, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};
