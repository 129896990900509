import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {isEmpty} from "../components/Utils";
import { useHistory } from "react-router-dom";
import Filter from "../components/videos/Filter";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from 'axios';
import Video from "../components/Video";
import {getPopularVideoPlaylist} from "../actions/playlist.action";

const PopularVideos = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const [videos, setVideos] = useState(null);
	const [disciplineSelect, setDisciplineSelect] = useState("");
	const [devicesSelect, setDevicesSelect] = useState("");
	const [regionSelect, setRegionSelect] = useState("");
	const [scrollPage, setScrollPage] = useState(0);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;

	const playlistsPopularVideoReducer = useSelector(
		(state) => state.playlistReducer.playlistsPopularVideoReducer
	);

	useEffect(() => {
		dispatch(getPopularVideoPlaylist(0, 10));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(playlistsPopularVideoReducer)) {
			setVideos(playlistsPopularVideoReducer.videos)
		}
	}, [playlistsPopularVideoReducer]);

	const clickBack = () => {
		history.push(`/`);
	};

	const onSelectFilter = (discipline, device, region) => {
		setDisciplineSelect(discipline);
		setDevicesSelect(device);
		setRegionSelect(region);
		setScrollPage(0);
		dispatch(getPopularVideoPlaylist(0, 10));
	};

	const fetchVideos = async () => {
		let incrementPage = scrollPage + 1;
		setScrollPage(incrementPage);
		await axios
			.get(`${process.env.REACT_APP_API_URL}getPopularVideos&p=${incrementPage}&n=20`)
			.then((res) => {
				let responseVideos = res.data.videos;
				setVideos([...videos, ...responseVideos])
			})
			.catch((error) => {
				console.log(error)
			});
	};

	return (
		<main className="discipline-page">
			<div className="discipline-title">
				<div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
					<h1>
						<span onClick={() => clickBack()}/>
						{translations && !isEmpty(translations["most_viewed_videos"])
							? translations["most_viewed_videos"]
							: "Most viewed videos"}
					</h1>
				</div>
				<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="20%" height="2" fill="#1B3FFF"/>
					<rect x="20%" width="20%" height="2" fill="#D43D17"/>
					<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
					<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
					<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
				</svg>
			</div>

			{/*<Filter
				onSelectFilter={onSelectFilter}
			/>*/}

			<div className="content">
				{!isEmpty(videos) &&
				<InfiniteScroll
					dataLength={videos.length}
					next={fetchVideos}
					hasMore={true}
					style={{ overflow: 'hidden' }}
					//loader={"<span>Wait</span>"}
				>
					{videos.map((video) => {
						return (
							<Video video={video} key={video.id}/>
						)
					})}
				</InfiniteScroll>
				}
			</div>

		</main>
	);
};

export default PopularVideos;
