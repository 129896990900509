import {combineReducers} from "redux";
import {GET_VIDEOS_FAVORITE, GET_VIDEOS_FROM} from "../actions/videos.action";
import {GET_VIDEO, CLEAR_VIDEO} from "../actions/videos.action";

const initialState = [];

function getVideoReducer(state = initialState, action) {
	switch (action.type) {
		case GET_VIDEO:
			return action.payload;
		case CLEAR_VIDEO:
			return initialState;
		default:
			return state;
	}
}

function getVideosFromReducer(state = initialState, action) {
	switch (action.type) {
		case GET_VIDEOS_FROM:
			return action.payload;
		default:
			return state;
	}
}

function getVideoFavoriteReducer(state = initialState, action) {
	switch (action.type) {
		case GET_VIDEOS_FAVORITE:
			return action.payload;
		default:
			return state;
	}
}
export default combineReducers({
	getVideosFromReducer,
	getVideoReducer,
	getVideoFavoriteReducer
});