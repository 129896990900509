import React from "react";

const RightArrow = (props) => {
	const { onClick } = props;
	return (
		<button
			className={
				props.className.includes("slick-disabled")
					? "d-none arrow-slideshow right"
					: "arrow-slideshow right"
			}
			onClick={onClick}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
			>
				<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
			</svg>
		</button>
	);
};

export default RightArrow;
