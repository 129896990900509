import videojs from 'video.js';
import {isEmpty} from "../../components/Utils";

const Plugin = videojs.getPlugin('plugin');

class PreRoll extends Plugin {
    constructor(player, options) {
        super(player, options);
        this.videoEl = player.el();
        this.currentIndex = 0;
        this.adsText = document.createElement("span");
        this.on("statechanged", this.preRollStart);
        this.setState({"videos": null});
    }

    preRollUpdate = (videos) => {
        this.ended = Object.keys(videos).length < 1;
        this.setState({"videos": videos});
    };

    preRollStart() {
        this.currentIndex = 0;
        this.adsText.remove();

        if (this.nextListener) {
            this.player.off("ended", this.nextListener);
        }

        if (!isEmpty(this.state.videos)) {
            this.player.ready(() => {

            });

            this.player.addClass('vjs-preroll');
            this.adsText.classList.add("ads-mute");
            this.adsText.addEventListener("click",  () => {
                if (this.player.muted()) {
                    this.player.muted(false);
                    if (this.adsText.classList.contains("mute")) {
                        this.adsText.classList.remove("mute");
                    }
                } else {
                    this.player.muted(true);
                    if (!this.adsText.classList.contains("mute")) {
                        this.adsText.classList.add("mute");
                    }
                }
            });

            this.nextListener = () => {
                this.currentIndex++;
                if (this.state.videos !== null && this.state.videos.hasOwnProperty(this.currentIndex)) {
                    this.changeVideo(this.currentIndex, true);
                    console.log("ended preroll index: " + this.currentIndex);
                } else {
                    this.reset();
                    this.player.controlBar.progressControl.show();
                    this.player.controlBar.progressControl.enable();
                    this.ended = true;
                    this.player.trigger("endallpreroll");
                }
            };

            this.player.on("ended", this.nextListener);

            this.videoEl.appendChild(this.adsText);

            this.changeVideo(0, false);

        } else {
            this.reset();
        }
    }

    changeVideo(index, autoStart) {

        if (isEmpty(this.state.videos[index].link)) {

            this.reset();
            this.player.controlBar.progressControl.show();
            this.player.controlBar.progressControl.enable();
            this.ended = true;
            this.player.trigger("endallpreroll");

        } else {
            this.adsText.textContent = "AD " + (this.currentIndex + 1) + " OF " + Object.keys(this.state.videos).length;

            let videoUrl = this.state.videos[index].link;
            this.player.src({ type: 'video/mp4', src: videoUrl });
            this.player.load();

            if (!isEmpty(this.state.videos[index].disable_sound) && this.state.videos[index].disable_sound === 1) {
                this.player.muted(true);
            } else {
                this.player.muted(false);
            }

            this.player.controlBar.progressControl.hide();
            this.player.controlBar.progressControl.disable();

            if (autoStart) {
                let playPromise = this.player.play();
                if (playPromise !== undefined) {
                    playPromise.then(() => {

                    }).catch(error => {
                        this.player.pause()
                    });
                }
            }
        }

    }

    reset() {
        this.setState({"videos": null});
        this.adsText.remove();
        if (this.player.controlBar) {
            this.player.controlBar.progressControl.show();
            this.player.controlBar.progressControl.enable();
        }
        this.player.removeClass("vjs-preroll");
    }
}
videojs.registerPlugin('preroll', PreRoll);
