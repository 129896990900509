import React, {useEffect, useState} from "react";
import { useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {isEmpty} from "../components/Utils";
import Moment from "react-moment";
import {Helmet} from "react-helmet-async";
import {getInfo} from "../actions/info.action";
import parse from "html-react-parser";

const Info = ({ match }) => {

	const {
		params: { infoId },
	} = match;

	const dispatch = useDispatch();
	const history = useHistory();
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const infoReducer = useSelector((state) => state.infoReducer.getInfoReducer);
	const [info, setInfo] = useState([]);
	const [styles, setStyles] = useState({});

	useEffect(() => {
		if (settings?.videodescr_bg_color) {
			setStyles((prevState) => ({...prevState, backgroundColor: settings.videodescr_bg_color}));
		}
		if (settings?.videodescr_text_color) {
			setStyles((prevState) => ({...prevState, color: settings.videodescr_text_color}));
		}
	}, [settings]);

	useEffect(() => {
		dispatch(getInfo(infoId))
	}, [dispatch, infoId]);

	useEffect(() => {
		if (infoReducer.info) {
			setInfo(infoReducer.info)
		}
	}, [infoReducer]);

	const clickBack = () => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.push(`/`);
		}
	};

	return (
		<div className="info-page">

			{!isEmpty(infoReducer) && (
				<Helmet>
					<title>{`${process.env.REACT_APP_CLIENT_NAME}`}</title>
					<meta name="title" content={`${process.env.REACT_APP_CLIENT_NAME}`} />
					<meta property="og:title" content={`${process.env.REACT_APP_CLIENT_NAME}`} />
					{/*<meta property="og:description" content={`${video.description}`} />
					<meta property="og:image" content={`${video.poster}`} />*/}
				</Helmet>
			)}

			<div className="container">
				<div className="my-3 d-flex">
					<div className="d-flex cursor-pointer" onClick={() => clickBack()}>
						<span className="back">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" fill="none"><circle cx="44" cy="44" r="42" strokeWidth="4" stroke={settings?.general_text_color ? settings.general_text_color : "white"}/><path d="M47.6,60.9L31.5,46.2c-0.7-0.7-1.1-1.4-1.1-2.2c0-0.8,0.4-1.6,1.1-2.2l16.1-14.7c0.5-0.5,1.2-0.8,2-0.8 c1.6,0,2.9,1.2,2.9,2.7c0,0.7-0.3,1.4-0.9,2L37.3,44l14.3,13c0.6,0.5,0.9,1.2,0.9,2c0,1.5-1.3,2.7-2.9,2.7 C48.8,61.7,48.1,61.4,47.6,60.9z" fill={settings?.general_text_color ? settings.general_text_color : "white"}/></svg>
						</span>
						{/*<span className="d-flex my-auto">
							{translations && !isEmpty(translations["back"])
								? translations["back"]
								: "back"}
						</span>*/}
					</div>
				</div>
			</div>
			<div className="container">
				<div className="info-data" style={styles}>
					<div className="image-block">
						<img src={info.image} alt={`Card ${info.name}`}/>
					</div>
					<h2>{info.name}</h2>
					<div className="views">
						<Moment format="DD/MM/YYYY">{info.added}</Moment>
					</div>
					<div className="description">{info.content ? parse(info.content) : ""}</div>
					<div className="clear"/>
				</div>
			</div>

		</div>
	);
};

export default Info;