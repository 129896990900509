import axios from "axios";

export const GET_SLIDESHOW = "GET_SLIDESHOW";
export const GET_SLIDE_LIVE = "GET_SLIDE_LIVE";

export const getSlideShow = (quantity = 5, type = 0) => {
	return async (dispatch) => {
		await axios.get(`${process.env.REACT_APP_API_URL}getslides&n=${quantity}&t=${type}`)
			.then((res) =>
				dispatch({ type: GET_SLIDESHOW, payload: res.data })
			)
			.catch((error) => {
				console.log(error)
			});
	};
};

export const getSlideLive = (quantity = 5) => {
	return async (dispatch) => {
		await axios.get(`${process.env.REACT_APP_API_URL}getslides&n=${quantity}&t=1`)
			.then((res) =>
				dispatch({ type: GET_SLIDE_LIVE, payload: res.data })
			)
			.catch((error) => {
				console.log(error)
			});
	};
};