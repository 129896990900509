import videojs from 'video.js';
import {isEmpty, preloadImage} from "../../components/Utils";

const Plugin = videojs.getPlugin('plugin');

class Toasts extends Plugin {
    constructor(player, options) {
        super(player, options);
        this.videoEl = player.el();
        this.on("statechanged", this.createPermanent);
        this.default = {
            primary: null,
            secondary: null,
            secondary_display_time: null,
            secondary_hide_animation: null,
            secondary_show_animation: null,
            secondary_times: null,
        };
        this.setState(this.default);
    }

    dispose() {
        this.toastsStop();
        super.dispose();
    }

    toastsUpdate = (toasts) => {
        const toastsFormat = {...toasts};
        toastsFormat.primary = toastsFormat.primary && Object.values(toastsFormat.primary);
        toastsFormat.secondary = toastsFormat.secondary && Object.values(toastsFormat.secondary);

        this.setState(toastsFormat);

        //disabled if has olded
        this.player.off("play", this.toastsStart);
        this.player.off("pause", this.toastsStop);
        if (!isEmpty(this.state.primary) || !isEmpty(this.state.secondary)) {
            this.player.on("play", () => {
                if (this.player.preroll().ended) this.toastsStart()
            });
            this.player.on("pause", this.toastsStop);
        }

    };

    toastsStart() {
        this.toastsShow();
    };

    toastsStop() {
        if (this.pillsInterval) {
            clearInterval(this.pillsInterval);
        }
        if (this.pillsTimeout) {
            clearTimeout(this.pillsTimeout);
        }

    }

    createPermanent = () => {
        if (isEmpty(this.state.primary) && isEmpty(this.state.secondary)) {
            this.reset();
            return;
        }

        if (this.divElem) {
            this.divElem.remove();
            this.secondaryDiv.remove();
            this.primaryDiv.remove();
        }

        if (this.pillsInterval) {
            clearInterval(this.pillsInterval);
        }
        if (this.pillsTimeout) {
            clearTimeout(this.pillsTimeout);
        }

        if (!isEmpty(this.state) && (!isEmpty(this.state.primary) || !isEmpty(this.state.secondary))) {
            this.divElem = document.createElement('div');
            this.secondaryDiv = document.createElement("div");
            this.divElem.classList.add("vjs-toasts-content");
        }

        if (!isEmpty(this.state.secondary)) {
            //let totalImagesCount = this.state.secondary.length + this.state.primary.length;
            //let percentPerBlock = (100 / totalImagesCount);
            //let secondaryPercentWidth = percentPerBlock * this.state.secondary.length;
            //this.secondaryDiv.style.width = secondaryPercentWidth + "%";

            this.secondaryDiv.classList.add("toasts-secondary");
            this.secondaryDiv.classList.add("animate__animated");
            this.secondaryDiv.classList.add(this.state.secondary_hide_animation);

            this.state.secondary.forEach(element => {
                let img = preloadImage(element);
                //img.style.width = Math.floor(100 / this.state.secondary.length) + "%";
                this.secondaryDiv.appendChild(img);
            });

            this.divElem.appendChild(this.secondaryDiv);

            /*this.pillsTimeout = setTimeout(() => {
                if (this.secondaryDiv.classList.contains(this.state.secondary_show_animation)) {
                    this.secondaryDiv.classList.remove(this.state.secondary_show_animation);
                }
                if (!this.secondaryDiv.classList.contains(this.state.secondary_hide_animation)) {
                    this.secondaryDiv.classList.add(this.state.secondary_hide_animation);
                }
            }, this.state.secondary_display_time * 1000);*/
        }

        if (!isEmpty(this.state.primary)) {
            //let totalImagesCount = (this.state.secondary ? this.state.secondary.length : 0) + this.state.primary.length;
            //let percentPerBlock = (100 / totalImagesCount);
            //let primaryPercentWidth = percentPerBlock * this.state.primary.length;
            this.primaryDiv = document.createElement("div");
            //this.primaryDiv.style.width = primaryPercentWidth + "%";
            this.primaryDiv.classList.add("toasts-primary");

            if (this.state.background) {
                this.divElem.classList.add(`toasts-bg-${this.state.background}`);
            }

            this.state.primary.forEach(element => {
                let img = preloadImage(element);
                //img.style.width = Math.floor(100 / this.state.primary.length) + "%";
                this.primaryDiv.appendChild(img);
            });

            this.divElem.appendChild(this.primaryDiv);
        }
    };

    toastsShow = () => {

        if (this.pillsInterval) {
            clearInterval(this.pillsInterval);
        }

        this.pillsInterval = setInterval(() => {

            if (this.pillsTimeout) {
                clearTimeout(this.pillsTimeout);
            }

            if (this.secondaryDiv.classList.contains(this.state.secondary_hide_animation)) {
                this.secondaryDiv.classList.remove(this.state.secondary_hide_animation);
            }
            if (!this.secondaryDiv.classList.contains(this.state.secondary_show_animation)) {
                this.secondaryDiv.classList.add(this.state.secondary_show_animation);
            }

            this.pillsTimeout = setTimeout(() => {
                if (this.secondaryDiv.classList.contains(this.state.secondary_show_animation)) {
                    this.secondaryDiv.classList.remove(this.state.secondary_show_animation);
                }
                if (!this.secondaryDiv.classList.contains(this.state.secondary_hide_animation)) {
                    this.secondaryDiv.classList.add(this.state.secondary_hide_animation);
                }
            }, this.state.secondary_display_time * 1000);

        }, this.state.secondary_times * 60000);

        this.videoEl.appendChild(this.divElem);
    };

    reset() {
        this.setState(this.default);

        if (this.divElem) {
            this.divElem.remove();
            this.secondaryDiv.remove();
            this.primaryDiv.remove();
        }
        if (this.pillsInterval) {
            clearInterval(this.pillsInterval);
        }
        if (this.pillsTimeout) {
            clearTimeout(this.pillsTimeout);
        }

    }
}

videojs.registerPlugin('toasts', Toasts);