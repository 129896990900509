import React, {useContext, useEffect} from "react";
import Header from "../Header";
import Footer from "../Footer";
import { MyContext } from "../../contexts/MyContext";
import Notification from "../header/Notification";

const Home = (props) => {
	const { rootState, isLoggedIn } = useContext(MyContext);
	const { isAuth } = rootState;

	useEffect(() => {
		async function fetchData() {
			await isLoggedIn();
		}
		fetchData();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Header />
			{isAuth !== null && isAuth && <Notification />}
			{props.children}
			<Footer />
		</>
	);
};

export default Home;
