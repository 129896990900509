import axios from "axios";
export const GET_VIDEOS_FROM = "GET_VIDEOS_FROM";
export const GET_VIDEO = "GET_VIDEO";
export const CLEAR_VIDEO = "CLEAR_VIDEO";
export const GET_VIDEOS_FAVORITE = "GET_VIDEOS_FAVORITE";

export const getVideo = (id = "") => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		let url = `${process.env.REACT_APP_API_URL}getVideo&i=${id}`;
		if (loginToken) {
			url += `&key=${loginToken}`
		}
		await axios
			.get(url)
			.then((res) => dispatch({type: GET_VIDEO, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const clearVideo = () => {
	return async (dispatch) => {
		dispatch({type: CLEAR_VIDEO, payload: []});
	};
};

export const getVideosFrom = (from = "", id = "", discipline = 0, device = 0, region = 0, page = 0, quantity = 20, order = 'addedon') => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getvideosfrom&o=${order}&f=${from}&i=${id}&di=${discipline}&de=${device}&re=${region}&p=${page}&n=${quantity}`)
			.then((res) => dispatch({type: GET_VIDEOS_FROM, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const getVideoFavorite = (page = "0", type = "3", quantity = "10") => {
	return async (dispatch) => {
		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			let form = new FormData();
			form.append("key", loginToken);
			form.append("t", type);
			form.append("p", page);
			form.append("n", quantity);
			await axios.post(`${process.env.REACT_APP_API_URL}getVideoFavorite`, form)
				.then((res) =>
					dispatch({type: GET_VIDEOS_FAVORITE, payload: res.data})
				).catch((error) => {
					console.log(error)
				});
		}
	};
};
