import React, {useEffect, useState} from "react";
import {isMobileDevise} from "../components/Utils";
import {useLocation} from "react-router";

const RedirectApp = () => {
	const location = useLocation();
	const [redirectUrl, setRedirectUrl] = useState("/");
	const [isMobile, setIsMobile] = useState(true);

	useEffect(() => {
		setIsMobile(isMobileDevise());
	}, [location]);

	useEffect(() => {
		if (isMobile) {
			window.location.href = "ffgym://path/into/app" + location.search;
			setRedirectUrl("ffgym://path/into/app" + location.search);
		} else {
			window.location.href = "/";
			setRedirectUrl("/");
		}
	}, [isMobile, location.search]);

	return (
		<div>
			<a href={redirectUrl}>Open in APP</a>
		</div>
	)
};

export default RedirectApp;
