import { GET_NOTIFICATIONS } from "../actions/notifications.action";

const initialState = [];

export default function notificationsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_NOTIFICATIONS:
			return action.payload;
		default:
			return state;
	}
}
