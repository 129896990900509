import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {getDisciplines} from "../actions/disciplines.action";
import {isEmpty} from "../components/Utils";
import {useMediaQuery} from "react-responsive";
import {getVideosFrom} from "../actions/videos.action";
import Filter from "../components/discipline/Filter";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import {Helmet} from "react-helmet-async";
import Video from "../components/Video";

const DisciplinePage = () => {

	const { discipline } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const disciplinesReducer = useSelector((state) => state.disciplineReducer.getDisciplinesReducer);
	const videosReducer = useSelector((state) => state.videosReducer.getVideosFromReducer);
	const [devicesSelect, setDevicesSelect] = useState("");
	const [regionSelect, setRegionSelect] = useState("");
	const [scrollPage, setScrollPage] = useState(0);
	const [videos, setVideos] = useState(null);
	const [hasNextPage, setHasNextPage] = useState(true);
	const [selectDiscipline, setSelectDiscipline] = useState({
		id: "",
		name: "",
		name_web: "",
		pic: ""
	});

	useEffect(() => {
		dispatch(getDisciplines(20));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(selectDiscipline.id)) {
			dispatch(getVideosFrom("discipline", selectDiscipline.id));
		}
		// eslint-disable-next-line
	}, [selectDiscipline]);


	useEffect(() => {
		if (!isEmpty(videosReducer)) {
			setVideos(videosReducer.videos)
		}
	}, [videosReducer]);

	useEffect(() => {
		if (!isEmpty(disciplinesReducer.disciplines)) {
			let select = disciplinesReducer.disciplines.filter((dis) => {
				return discipline === dis.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
			});
			setSelectDiscipline(...select);
		}
	}, [disciplinesReducer, discipline]);

	const onSelectFilter = (devicesSelect, regionSelect) => {
		setHasNextPage(true);
		setScrollPage(0);
		dispatch(getVideosFrom("discipline", selectDiscipline.id, 0, devicesSelect, regionSelect));
		setDevicesSelect(devicesSelect);
		setRegionSelect(regionSelect);
	};

	const fetchVideos = async () => {
		let incrementPage = scrollPage + 1;
		setScrollPage(incrementPage);
		await axios
			.get(`${process.env.REACT_APP_API_URL}getvideosfrom&f=discipline&i=${selectDiscipline.id}&di=0&de=${devicesSelect}&re=${regionSelect}&p=${incrementPage}&n=20`)
			.then((res) => {
				let responseVideos = res.data.videos;
				setVideos([...videos, ...responseVideos]);
				setHasNextPage(res.data.nextpage !== null);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const clickBack = () => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.push(`/discipline`);
		}
	};

	return (
		<>
			{!isEmpty(selectDiscipline.name) && (
				<Helmet>
					<meta name="title" content={`${process.env.REACT_APP_CLIENT_NAME} - ${selectDiscipline.name}`} />
					<meta property="og:title" content={`${process.env.REACT_APP_CLIENT_NAME} - ${selectDiscipline.name}`} />
					<meta property="og:description" content={`${process.env.REACT_APP_CLIENT_NAME} - ${selectDiscipline.name}`} />
					{!isEmpty(selectDiscipline.pic) && (
						<meta property="og:image" content={`${selectDiscipline.pic}`} />
					)}
				</Helmet>
			)}


			<main className="discipline-page">
				<div className="discipline-title">
					<div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
						<h1>
							<span onClick={() => clickBack()}/>
							{isMobile ? selectDiscipline.name : selectDiscipline.name_web}
						</h1>
					</div>
					<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="20%" height="2" fill="#1B3FFF"/>
						<rect x="20%" width="20%" height="2" fill="#D43D17"/>
						<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
						<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
						<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
					</svg>
				</div>


				<Filter
					onSelectFilter={onSelectFilter}
					disciplineId={selectDiscipline.id}
				/>

				<div className="content">
					{!isEmpty(videos) &&
					<InfiniteScroll
						dataLength={videos.length}
						next={fetchVideos}
						hasMore={hasNextPage}
						style={{ overflow: 'hidden' }}
					>
						{videos.map((video) => {
							return (
								<Video video={video} key={video.id}/>
							)
						})}
					</InfiniteScroll>
					}
				</div>
			</main>
		</>
	);
};

export default DisciplinePage;
