import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import {isEmpty} from "../components/Utils";
import {useHistory} from "react-router-dom";
import {getSearch} from "../actions/search.action";
import PlaylistVideos from "../components/PlaylistVideos";
import PlaylistEvents from "../components/PlaylistEvents";

const Search = () => {
	const { inputValues } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const searchReducer = useSelector(
		(state) => state.searchReducer.searchDataReducer
	);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;
	useEffect(() => {
		dispatch(getSearch(inputValues));
	}, [inputValues, dispatch]);

	const clickBack = () => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.push(`/`);
		}
	};

	return (
		<main className="categories-page">
			<div className="discipline-title">
				<div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
					<h1>
						<span onClick={() => clickBack()}/>
						{isMobile ? inputValues : inputValues}
					</h1>
				</div>
				<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="20%" height="2" fill="#1B3FFF"/>
					<rect x="20%" width="20%" height="2" fill="#D43D17"/>
					<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
					<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
					<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
				</svg>
			</div>

			<div className="playlist-videos filter white">
				{!isEmpty(searchReducer.live) && !isEmpty(searchReducer.live.events) &&
					<PlaylistEvents
						events={searchReducer.live.events}
						showCount={searchReducer.live.total}
						title={translations && !isEmpty(translations["next_directs"])
							? translations["next_directs"]
							: "Next directs"
						}
					/>
				}
			</div>

			<div className="playlist-videos filter white">
				{!isEmpty(searchReducer.video) && !isEmpty(searchReducer.video.video) &&
					<PlaylistVideos
						hideSeeMore={true}
						showCount={searchReducer.video.total}
						name={searchReducer.video.name}
						videos={searchReducer.video.video}
					/>
				}
			</div>

		</main>
	);
};

export default Search;
