import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import Explorer from "../../pages/Explorer";
import Player from "../../pages/Player";
import Live from "../../pages/Live";
import Account from "../../pages/Account";
import Home from "./Home.routes";
import Disciplines from "../../pages/Disciplines";
import Directs from "../../pages/Directs";
import Videos from "../../pages/Videos";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import DisciplinePage from "../../pages/DisciplinePage";
import Parameters from "../account/Parameters";
import Info from "../../pages/Info";
import Search from "../../pages/Search";
import RedirectApp from "../../pages/RedirectApp";
import Categories from "../../pages/Categories";
import Delete from "../../pages/Delete";
import PlaylistVideos from "../../pages/PlaylistVideos";
import PopularVideos from "../../pages/PopularVideos";
import LatestVideos from "../../pages/LatestVideos";
import FavoriteVideos from "../../pages/FavoriteVideos";

const Routes = () => {
	return (
		<Router>
			<Switch>
				<Route>
					<Home>
						<Switch>
							<Route path="/" exact component={Explorer} />
							<Route path="/search/:inputValues" exact component={Search} />
							<Route path="/settings" exact component={Parameters} />
							<Route path="/info/:infoId" exact component={Info} />
							<Route path="/directs" exact component={Directs} />
							<Route path="/videos" exact component={Videos} />
							<Route path="/videos/popular" exact component={PopularVideos} />
							<Route path="/videos/latest" exact component={LatestVideos} />
							<Route path="/videos/my-videos" exact component={FavoriteVideos} />
							<Route path="/playlists/:playlistId" exact component={PlaylistVideos} />
							<Route path="/privacy-policy" exact component={PrivacyPolicy} />
							<Route path="/politique-de-confidentialite" exact component={PrivacyPolicy} />
							<Route path="/discipline" exact component={Disciplines} />
							<Route path="/discipline/:discipline" exact component={DisciplinePage} />
							<Route path="/app-redirect" exact component={RedirectApp}/>
							<Route path="/delete" exact component={Delete}/>

							<Route path="/categories/:programId/:childId?" exact component={Categories}/>
							<Route exact path="/video/:videoID" component={Player} />
							<Route exact path="/live/:evtID/" component={Live} />


							<Route exact path="/account/:method?" component={Account} />
							<Redirect to="/" />
						</Switch>
					</Home>
				</Route>
			</Switch>
		</Router>
	);
};

export default Routes;
