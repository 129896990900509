import React, {Fragment} from "react";
import { useSelector} from "react-redux";
import {isEmpty} from "../Utils";
import moment from "moment";


const NavBarMobileSubscriptionInvoice= (props) => {
    const setTypeMenu = props.setTypeMenu;
    const translations = useSelector(
        (state) => state.langReducer.translationsReducer.translate
    );
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    const invoices = stripeReducer.getInvoicesReducer;
    const paymentsIntents = stripeReducer.getPaymentsIntentsReducer;

    return (
        <div className="parameters-container">
            <div className="parameters-title">
                <div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
                    <h1>
                        <span onClick={() => setTypeMenu("subscription")}/>
                        {translations && !isEmpty(translations["invoice"])
                            ? translations["invoice"]
                            : "Invoice"
                        }
                    </h1>
                </div>
            </div>
            <div className="content">
                <h4>
                    {translations && !isEmpty(translations["invoice"])
                        ? translations["invoice"]
                        : "Invoice"
                    }
                </h4>
                <div className="invoices text-small">
                    {invoices ? (
                        invoices.map((invoice) => {
                            return (
                                <Fragment key={invoice.id}>
                                    <div>
                                        <span>
                                            {(invoice.total / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}
                                        </span>
                                        <span>{invoice.lines.data[0].description}</span>
                                        <span className="no-wrap mx-1">
                                            {invoice?.status === "paid"
                                                ? moment.unix(invoice.status_transitions.paid_at).format("DD-MM-YYYY HH:mm")
                                                : (invoice?.status === "open" ? <a href={invoice.hosted_invoice_url} target='_blank' rel="noreferrer" style={{color: "black"}}>{invoice?.status}</a> : invoice?.status)
                                            }
                                        </span>

                                        <span className="download" onClick={() => window.open(invoice.invoice_pdf)}>
                                            <img src="/img/icons/download.svg" alt="download"/>
                                        </span>
                                    </div>
                                </Fragment>
                            )
                        })
                    ) : (
                        <div>
                            <span>You don't have an invoices.</span>
                        </div>
                    )}
                </div>  
            </div>

            <div className="content">
                <h4>
                    {translations && !isEmpty(translations["payments"])
                        ? translations["payments"]
                        : "Payments"
                    }
                </h4>
                <div className="invoices text-small">
                    {paymentsIntents ? (
                        paymentsIntents.map((intent) => {
                            return (
                                <Fragment key={intent.id}>
                                    <div>
                                        <span>
                                            {(intent.amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}
                                        </span>
                                        <span>{intent.description}</span>
                                        <span className="no-wrap mx-1">
                                            {intent?.status === "succeeded"
                                                ? moment.unix(intent.created).format("DD-MM-YYYY HH:mm")
                                                : (<a href={intent.hosted_invoice_url} target='_blank' rel="noreferrer" style={{color: "black"}}>{intent?.status}</a>)
                                            }
                                        </span>

                                        <span className="download" onClick={() => window.open(intent.invoice_pdf)}>
                                            <img src="/img/icons/download.svg" alt="download"/>
                                        </span>
                                    </div>
                                </Fragment>
                            )
                        })
                    ) : (
                        <div>
                            <span>You don't have an invoices.</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NavBarMobileSubscriptionInvoice;
