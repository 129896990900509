import React, { useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useMediaQuery } from "react-responsive";
import {getDisciplines} from "../actions/disciplines.action";
import {isEmpty} from "../components/Utils";
import {Link, NavLink} from "react-router-dom";
import { gsap } from 'gsap';

const Disciplines = () => {
	const dispatch = useDispatch();
	const cursor = useRef();
	const imageHover = useRef();
	const imageBlock = useRef();
	const disciplinesReducer = useSelector((state) => state.disciplineReducer.getDisciplinesReducer);
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);

	const [disciplines, setDisciplines] = useState([]);

	const [prevPositionXRef, setPrevPositionXRef] = useState(0);
	const [rotateImage, setRotateImage] = useState(0);
	const [image, setImage] = useState("");
	const [widthImage, setWidthImage] = useState(0);
	const [heightImage, setHeightImage] = useState(0);
	const [cursorMouse, setCursorMouse] = useState(0);

	const updateMousePosition = (ev) => {
		setCursorMouse(ev);
	};

	const stopMouse = () => {
		setPrevPositionXRef(cursorMouse.x);
		setRotateImage(0)
	};

	useEffect( () => {
		let imageBlockHover = imageBlock.current;
		let imageCursorHover = cursor.current;
		let x = !isEmpty(cursorMouse.x) ? cursorMouse.x : 0;
		let y = !isEmpty(cursorMouse.y) ? cursorMouse.y : 0;
		let wImage = !isEmpty(widthImage) ? widthImage : 0;
		let hImage = !isEmpty(heightImage) ? heightImage : 0;
		let rotate = !isEmpty(rotateImage) ? rotateImage : 0;
		//let prevXRef = !isEmpty(prevPositionXRef) ? prevPositionXRef : 0;

		if (imageBlockHover) {
			gsap.to(imageBlockHover, 0.8, {
				ease: 'Sine.easeOut',
				x: x - (wImage - 40),
				y: y - (hImage - 40),
				rotate: rotate,
				transformOrigin: "bottom center",
				onComplete: stopMouse,
				//onStart: () => setRotateImage((prevXRef - x) / 50)
			});
		}

		if (imageCursorHover) {
			gsap.to(imageCursorHover, 0.3, {
				ease: 'Sine.easeOut',
				x: x - 40,
				y: y - 40
			});
		}


		return () => {
			gsap.killTweensOf(imageBlockHover);
			gsap.killTweensOf(imageCursorHover);
		}
		// eslint-disable-next-line
	}, [cursorMouse, prevPositionXRef, widthImage, heightImage]);

	useEffect(() => {
		let imageLink = imageHover.current;

		if (imageLink) {
			gsap.to(imageLink, 0.3, {
				ease: 'Sine.easeOut',
				startAt: {x: rotateImage <= 0 ? '-100%' : '100%'},
				x: 0,
			});
		}

		return () => {
			gsap.killTweensOf(imageLink);
		}
		// eslint-disable-next-line
	}, [image]);

	useEffect(() => {
		dispatch(getDisciplines(20));
	}, [dispatch]);

	useEffect(() => {
		window.addEventListener("mousemove", updateMousePosition);
		return () => {
			window.removeEventListener("mousemove", updateMousePosition);
		}
	}, []);

	useEffect(() => {
		setDisciplines(disciplinesReducer.disciplines)
	}, [disciplinesReducer]);

	return (
		<main className={!isMobile ? "disciplines" : "discipline-page"} style={{ background: !isMobile && !isEmpty(settings) ? settings.navigation_color : ""}}>
			{isMobile && (
				<div className="discipline-title">
					<div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
						<h1>
							Disciplines
						</h1>
					</div>
					<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="20%" height="2" fill="#1B3FFF"/>
						<rect x="20%" width="20%" height="2" fill="#D43D17"/>
						<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
						<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
						<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
					</svg>
				</div>
			)}
			{!isMobile ?
				(
					<div className="disciplines-desktop">
						<ul>
							{!isEmpty(disciplines) > 0 && (
								disciplines.map((discipline, index) => {
									return (
										<li key={index}
											onMouseEnter={() => setImage(discipline.pic)}
											onMouseLeave={() => setImage(false)}
										>
											<NavLink to={`/discipline/${discipline.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")}`}>{isMobile ? discipline.name : discipline.name_web}</NavLink>
										</li>

									)
								})
							)}

							{cursorMouse !== 0 &&
							<svg ref={cursor} className="cursor" width="80" height="80" viewBox="0 0 80 80">
								<circle className="cursor__inner" cx="40" cy="40" r="20"/>
							</svg>
							}

							<div ref={imageBlock} className="image_block" style={{display: image ? "block" : "none"}}>
								<img ref={imageHover} src={image ? image : undefined} alt="" onLoad={() => {setWidthImage(imageHover.current.naturalWidth); setHeightImage(imageHover.current.naturalHeight)}}/>
							</div>

						</ul>

					</div>
				) : (
					<div className="categories">
						{!isEmpty(disciplines) && (
							disciplines.map((discipline) => {
								return (
									<Link
										key={discipline.id}
										to={`/discipline/${discipline.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")}`}
									>
										<div className="category" key={discipline.id}>
											<img src={discipline.pic} alt={isMobile ? discipline.name : discipline.name_web}/>
											<h4>{isMobile ? discipline.name : discipline.name_web}</h4>
										</div>
									</Link>
								)
							}))
						}
					</div>
				)}
		</main>
	);
};

export default Disciplines;
