import videojs from 'video.js';
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

// Default options for the plugin.
const defaults = {};

// Cross-compatibility for Video.js 5 and 6.
const registerPlugin = videojs.registerPlugin || videojs.plugin;
// const dom = videojs.dom || videojs;

const onPlayerReady = (player, options) => {
    player.addClass('vjs-fullscreen-iphone');

    const fullScreenDefault = player.controlBar.el().querySelector('.vjs-fullscreen-control');
    fullScreenDefault.classList.add("vjs-hidden");

    player.fullscreenIphone = player.addChild('fullscreenIphoneButton', options);
    player.controlBar.el().appendChild(player.fullscreenIphone.el());
};

const fullscreenIphone = function(options) {
    this.ready(() => {
        onPlayerReady(this, videojs.mergeOptions(defaults, options));
    });
};

class FullscreenIphoneButton extends Button {
    constructor(player, options) {
        super(player, options);
        this.controlText('Fullscreen');
        window.addEventListener("resize", this.resizeListener);
    }

    dispose() {
        super.dispose();
        window.removeEventListener("resize", this.resizeListener);
    }

    resizeListener = () => {

        if (this.player_.hasClass('vjs-fullscreen-iphone-video')) {
            let width = window.innerWidth;
            let maxHeight = Math.ceil(width / 1.777);
            this.player_.el().style.width = width + "px";
            this.player_.el().style.maxHeight = maxHeight + "px";
        }

    };

    buildCSSClass() {
        return "vjs-fullscreen-iphone-control vjs-control vjs-button";
    }

    handleClick() {

        let width = window.innerWidth;
        let maxHeight = Math.ceil(width / 1.777); //coefficient 16/9
        let background = document.createElement('div');
        background.id = "fullscreen_background";
        if (this.options_.openNewPage) {
            if (this.options_.closeMe) {
                window.close();
            } else if (typeof(this.options_.redirectTo) !== 'undefined' && this.options_.redirectTo !== ''){
                // console.log(this.options_.redirectTo);
                this.player_.pause();
                window.top.open(this.options_.redirectTo, '', 'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no');
            }
        } else {
            if (this.player_.hasClass('vjs-fullscreen-iphone-video')) {
                document.getElementById("video_player_bg").innerHTML = "";
                this.player_.el().style.maxHeight = null;
                this.player_.el().style.width = null;
                this.player_.removeClass('vjs-fullscreen-iphone-on');
                this.player_.removeClass('vjs-fullscreen-iphone-video');
            } else {
                document.getElementById("video_player_bg").appendChild(background);
                this.player_.el().style.maxHeight = maxHeight + "px";
                this.player_.el().style.width = width + "px";
                this.player_.addClass('vjs-fullscreen-iphone-on');
                this.player_.addClass('vjs-fullscreen-iphone-video');
            }
        }

    }
}

Component.registerComponent('FullscreenIphoneButton', FullscreenIphoneButton);

registerPlugin('fullscreenIphone', fullscreenIphone);

export default fullscreenIphone;