import React, {Fragment, useContext, useState} from "react";
import { useSelector } from "react-redux";
import { MyContext } from "../../contexts/MyContext";
import {isEmpty} from "../Utils";

const LanguageParam = () => {

	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const [stateEdit, setStateEdit] = useState(false);
	const { rootState, isLoggedIn } = useContext(MyContext);
	const { theUser } = rootState;

	const { updateUserData } = useContext(MyContext);

	const submitForm = async (language) => {
		let userInfo = {
			username: theUser.username,
			lang: language
		};
		const data = await updateUserData(userInfo);
		if (data.result === "ok") {
			if (document.querySelector("#validUpdate")) {
				setTimeout(() => {
					document
						.querySelector("#validUpdate")
						.classList.add("great", "display");
				}, 10);
				setTimeout(() => {
					document
						.querySelector("#validUpdate")
						.classList.remove("great", "display");
				}, 3000);
			}
			await isLoggedIn();
		} else {
			console.log(data.message);
		}
	};

	return (
		<section className="languages block" style={{
			color: settings?.menu_text_color ? settings.menu_text_color : "white",
			background: !isEmpty(settings) ? settings.accent_color : ""
		}}>
			<div className="title">
				<h3>
					{translations && !isEmpty(translations["languages"])
						? translations["languages"]
						: "Languages"
					}
				</h3>
				<span className="edit" onClick={() => setStateEdit(!stateEdit)} style={{background: !isEmpty(settings) ? settings.hover_color : ""}}>
					{translations && !isEmpty(translations["button_edit"])
						? translations["button_edit"]
						: "Edit"
					}
				</span>
			</div>
			<article>
				<Fragment>
					<label>
						{translations && !isEmpty(translations["select_language"])
							? translations["select_language"]
							: "Select language"
						}
					</label>
					<div className="grid">
						<select disabled={!stateEdit} value={theUser.lang} className="select"
						        onChange={(e) => submitForm(e.target.value)}
						>
							<option value="fr">Français</option>
							<option value="en">English</option>
						</select>
					</div>
					<div id="validUpdate">
						{translations && !isEmpty(translations["text_updated"])
							? translations["text_updated"]
							: "The record has been updated"
						}
					</div>
				</Fragment>
			</article>
		</section>
	);
};

export default LanguageParam;
