import axios from "axios";

export const GET_PROGRAMS = "GET_PROGRAMS";

export const getPrograms = (n = 10) => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getprograms&n=${n}`)
			.then((res) => dispatch({type: GET_PROGRAMS, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};

export const GET_CHILD_PROGRAMS = "GET_CHILD_PROGRAMS";

export const getChildPrograms = (n = 10, parentId = '') => {
	return async (dispatch) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}getprograms&n=${n}&i=${parentId}`)
			.then((res) => dispatch({type: GET_CHILD_PROGRAMS, payload: res.data}))
			.catch((error) => {
				console.log(error)
			});
	};
};
