import React from "react";
import AccountParam from "../account/AccountParam";
import {isEmpty} from "../Utils";
import {useSelector} from "react-redux";

const NavBarMobileProfile = (props) => {
    const setTypeMenu = props.setTypeMenu;
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    return (
        <div className="parameters-container">
            <div className="parameters-title">
                <div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
                    <h1>
                        <span onClick={() => setTypeMenu(null)}/>
                        Votre profil
                    </h1>
                </div>
            </div>
            <div className="content">
                <AccountParam/>
            </div>
        </div>
    );
};

export default NavBarMobileProfile;
