import videojs from 'video.js';

const Plugin = videojs.getPlugin('plugin');

class Pills extends Plugin {
    constructor(player, options) {
        super(player, options);
        this.videoEl = player.el();
        this.index = 0;
        this.hiddenTime = 5; //sec
        this.on("statechanged", this.createPermanent);
    }

    updateState = (settings) => {
        this.setState(settings);

        //disabled if has olded
        this.player.off("play", this.startShow);
        this.player.off("pause", this.pillsStop);

        if (settings.images && settings.images.length > 0) {
            this.player.on("play", () => {
                if (this.player.preroll().ended) this.startShow(true)
            });
            this.player.on("pause", this.pillsStop);
        }

    };

    dispose() {
        this.pillsStop();
        super.dispose();
    };

    createPermanent = () => {
        if (!this.state.images || this.state.images.length < 1) {
            this.pillsStop();
            return;
        }

        if (this.divElem) {
            this.divElem.remove();
        }

    };

    startShow = (newInit) => {

        this.divElem = document.createElement('div');
        this.divElem.classList.add("vjs-pills-content", "animate__animated", "init-hide", this.state.hide_animation);
        this.videoEl.appendChild(this.divElem);

        if (newInit) {
            this.showHandler();
        }

        this.pillsInterval = setInterval(() => {

            this.index ++;

        }, this.state.secondary_times * 60000);

    };

    showHandler = () => {

        if (typeof this.state.images[this.index] !== 'undefined') {

            if (this.divElem.classList.contains("init-hide")) {
                this.divElem.classList.remove("init-hide");
            }
            if (!this.divElem.classList.contains(this.state.show_animation)) {
                this.divElem.classList.add(this.state.show_animation);
            }
            if (this.divElem.classList.contains(this.state.hide_animation)) {
                this.divElem.classList.remove(this.state.hide_animation);
            }

            if (this.hideTimeout) {
                clearTimeout(this.hideTimeout);
            }

            if (!this.img) {
                this.img = document.createElement('img');
            }

            this.img.src = this.state.images[this.index].image;

            if (this.state.images[this.index].link) {

                if (!this.a) {
                    this.a = document.createElement('a');
                }

                this.a.href = this.state.images[this.index].link;
                this.a.setAttribute('target', '_blank');
                this.a.onclick = (e) => {
                    this.player.pause();
                };
                this.a.appendChild(this.img);
                this.divElem.appendChild(this.a);
            } else {
                this.divElem.appendChild(this.img);
            }

            this.hideTimeout = setTimeout(() => {

                if (this.showTimeout) {
                    clearTimeout(this.showTimeout);
                }

                if (!this.divElem.classList.contains("init-hide")) {
                    this.divElem.classList.add("init-hide");
                }
                if (this.divElem.classList.contains(this.state.show_animation)) {
                    this.divElem.classList.remove(this.state.show_animation);
                }
                if (!this.divElem.classList.contains(this.state.hide_animation)) {
                    this.divElem.classList.add(this.state.hide_animation);
                }

                this.showTimeout = setTimeout(() => {
                    if (this.index >= this.state.images.length) {
                        this.index = 0;
                        this.showHandler();
                    } else {
                        this.showHandler();
                    }
                }, this.state.secondary_display_time * 1000);

            }, this.state.secondary_display_time * 1000);
        }

    };

    pillsStop = () => {

        this.index = 0;
        if (this.pillsInterval) {
            clearInterval(this.pillsInterval);
        }

        if (this.showTimeout) {
            clearTimeout(this.showTimeout);
        }

        if (this.hideTimeout) {
            clearTimeout(this.hideTimeout);
        }

        if (this.divElem) {
            this.divElem.remove();
        }
    };
}

videojs.registerPlugin('pills', Pills);