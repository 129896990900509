import axios from "axios";
import {isEmpty} from "../components/Utils";

export const GET_TRANSLATIONS = "GET_TRANSLATIONS";

export const getTranslations = (input) => {
	if(!isEmpty(input)){
		localStorage.setItem('ottLang', input.toLowerCase());
	}
	return async (dispatch) => {
		try {
			if (typeof input === "undefined" || input === null) {
				input = navigator.language.split("-")[0];
			}
			let res = await axios.get(
				`${process.env.REACT_APP_API_URL}getTranslate&l=${input.toLowerCase()}`
			);
			dispatch({ type: GET_TRANSLATIONS, payload: {"translate":res.data, "language": input} });
		} catch (err) {
			return console.log(err);
		}
	};
};