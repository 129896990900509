import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { isEmpty } from "../Utils";
import Slider from "react-slick";
import RightArrow from "../slider/RightArrow";
import LeftArrow from "../slider/LeftArrow";
import SliderStyle from "../SliderStyle";
import {getSlideShow} from "../../actions/slideshow.action";
import {useMediaQuery} from "react-responsive";

const Banner = () => {
	const dispatch = useDispatch();
	const slides = useSelector((state) => state.slideShowReducer.slideShowReducer.slides);
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

	useEffect(() => {
		dispatch(getSlideShow(5, 0));
	}, [dispatch]);
	//SLIDE
	let settings = {
		dots: false,
		infinite: true,
		fade: true,
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />
	};

	return (
		<div className="banner" id="scrollButton">
			<div className="slides-container">
				{!isEmpty(slides) ? (
					<Slider {...settings}>
						{!isEmpty(slides) &&
							slides.map((slide, index) => {
								return (
									<React.Fragment key={index}>
										 <SliderStyle slide={slide}/>
									</React.Fragment>
								);
							})}
					</Slider>
				) : (
					<div style={{height: !isMobile && "10rem"}}/>
				)}
			</div>
		</div>
	);
};

export default Banner;
