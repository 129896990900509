import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {isEmpty} from "../Utils";

const NavBar = () => {

	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);

	return (
		<nav>
			<div
				className="mobile-close"
				onClick={() =>
					document.querySelector(".main-header").classList.remove("menu-open")
				}
			>
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 18.8481L8.86739 9.98068L0 1.13645L1.11326 0.0231919L9.98065 8.86742L18.8558 0L19.9691 1.07462L11.0939 9.98068L20 18.9177L18.8867 20.0309L9.98065 11.1249L1.11326 19.9614L0 18.8481Z" fill={settings?.menu_text_color ? settings.menu_text_color : 'white'}/>
				</svg>
			</div>
			<ul>
				<li>
					<NavLink
						exact
						to="/"
						onClick={() =>
							document.querySelector(".main-header").classList.remove("menu-open")
						}
					>
						<svg className="mr-2" width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.59472 15.0257V12.5722C5.59471 11.9505 6.12688 11.4454 6.78605 11.4415H9.20641C9.86853 11.4415 10.4053 11.9477 10.4053 12.5722V15.0186C10.4053 15.5578 10.8665 15.9961 11.4383 16H13.0895C13.8607 16.0019 14.6011 15.7142 15.1471 15.2006C15.6931 14.6869 16 13.9894 16 13.262V6.29268C16 5.70511 15.7238 5.14777 15.246 4.7708L9.63619 0.539415C8.65559 -0.200702 7.25505 -0.176793 6.30349 0.596307L0.814326 4.7708C0.313887 5.13666 0.0147809 5.69565 0 6.29268V13.2549C0 14.771 1.30306 16 2.91046 16H4.52403C4.7993 16.0019 5.06399 15.9001 5.25935 15.7171C5.4547 15.5342 5.56457 15.2853 5.56456 15.0257H5.59472Z' fill={settings?.menu_text_color ? settings.menu_text_color : 'white'}/></svg>

						{translations && !isEmpty(translations["menu_home"])
							? translations["menu_home"]
							: "Home"
						}
					</NavLink>
				</li>
				<li>
					<NavLink
						exact
						to="/discipline"
						onClick={() =>
							document.querySelector(".main-header").classList.remove("menu-open")
						}
					>
						<svg className="mr-2" width='16' height='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.03 16'><rect width='6.77' height='6.83' rx='2.03' ry='2.03' fill={settings?.menu_text_color ? settings.menu_text_color : 'white'}/><path d='M4.73,9.17H2a2,2,0,0,0-2,2.05V14A2,2,0,0,0,2,16H4.73a2,2,0,0,0,2-2.05V11.22A2,2,0,0,0,4.73,9.17Z' transform='translate(0.01 0)' fill={settings?.menu_text_color ? settings.menu_text_color : 'white'}/><path d='M14,9.17h-2.7a2,2,0,0,0-2,2.05V14a2,2,0,0,0,2,2.05H14A2,2,0,0,0,16,14V11.22A2,2,0,0,0,14,9.17Z' transform='translate(0.01 0)' fill={settings?.menu_text_color ? settings.menu_text_color : 'white'}/><rect x='9.2' width='6.83' height='6.83' rx='2.14' ry='2.14' fill={settings?.menu_text_color ? settings.menu_text_color : 'white'}/></svg>

						{translations && !isEmpty(translations["menu_disciplines"])
							? translations["menu_disciplines"]
							: "Disciplines"
						}
					</NavLink>
				</li>
				<li>
					<NavLink
						exact
						to="/directs"
						onClick={() =>
							document.querySelector(".main-header").classList.remove("menu-open")
						}
					>
						<svg className="mr-2" width='16' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12.7286 8.81416L1.42357 15.8599C0.982635 16.1347 0.406742 15.9929 0.137279 15.5432C0.0475027 15.3934 0 15.2212 0 15.0456V0.954235C0 0.427225 0.418914 0 0.935669 0C1.10784 0 1.27667 0.0484453 1.42357 0.140003L12.7286 7.1857C13.1695 7.46051 13.3085 8.04783 13.0391 8.49752C12.9617 8.62669 12.8552 8.73523 12.7286 8.81416Z' fill={settings?.menu_text_color ? settings.menu_text_color : 'white'}/></svg>

						{translations && !isEmpty(translations["menu_directs"])
						? translations["menu_directs"]
						: "Directs"
					}

					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default NavBar;
