import { GET_PROGRAMS, GET_CHILD_PROGRAMS } from "../actions/programs.action";
import {combineReducers} from "redux";

const initialState = [];

function getProgramsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PROGRAMS:
			return action.payload;
		default:
			return state;
	}
}

function getProgramsChildReducer(state = initialState, action) {
	switch (action.type) {
		case GET_CHILD_PROGRAMS:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({
	getProgramsReducer,
	getProgramsChildReducer
});