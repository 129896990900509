import React, {Fragment, useContext, useEffect, useState} from "react";
import {isEmpty} from "../../../Utils";
import {useDispatch, useSelector} from "react-redux";
import Checkbox from "react-custom-checkbox";
import {MyContext} from "../../../../contexts/MyContext";
import {toggleModal} from "../../../../actions/modal.action";
import {
    getCustomer,
} from "../../../../actions/stripe.action";
import EditPayment from "./EditPayment";
import PriceSelector from "../../../stripe/PriceSelector";

const Plans = () => {
    const { createSubscribe, cancelSubscription } = useContext(MyContext);
    const dispatch = useDispatch();
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const customer = stripeReducer.getCustomerReducer;
    const products = stripeReducer.getProductsReducer;
    const activePlan = stripeReducer.getActiveCustomerProductReducer;
    const [checkedPlanId, setCheckedPlanId] = useState(null);
    const [checkedPriceId, setCheckedPriceId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [prices, setPrices] = useState([]);
    const [wait, setWait] = useState(false);
    const [openEditPayment, setOpenEditPayment] = useState(false);
    const [customerPlan, setCustomerPlan] = useState(false);

    useEffect(() => {
        if (!isEmpty(activePlan)) {
            setCustomerPlan(activePlan)
        }
    }, [activePlan]);

    useEffect(() => {
        if (openModal && products) {
            products.forEach((product) => {
                if (product.id === checkedPlanId) {
                    const newArrayOfObj = product.prices.map((item) => {
                        return {value: item.id, label: (item.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'}) + "/" + (!isEmpty(item?.recurring?.interval) ? item.recurring.interval : "one time"), ...item };
                    });
                    setPrices(newArrayOfObj);
                }
            })
        }
    }, [checkedPlanId, openModal, products]);

    const onChangePlan = async () => {
        if (!isEmpty(customer.id) && !isEmpty(checkedPriceId)) {
            setWait(true);
            let paymentResult = await createSubscribe(customer.id, checkedPriceId);

            setOpenModal(false);

            if (paymentResult.result) {
                if (paymentResult.result === "empty_payments_methods") {

                    setOpenEditPayment(true);

                } else if (paymentResult.result !== "succeeded" && paymentResult.result !== "ok") {
                    dispatch(toggleModal(
                        {
                            opened: true,
                            success: false,
                            text: !isEmpty(paymentResult.error) ? paymentResult.error : "Subscribe error"
                        })
                    )

                } else {
                    dispatch(getCustomer());

                    dispatch(toggleModal(
                        {
                            opened: true,
                            success: true,
                            text: "Subscribe success"
                        })
                    )
                }
            } else {
                dispatch(toggleModal(
                    {
                        opened: true,
                        success: false,
                        text: "Subscribe error"
                    })
                )
            }
            setWait(false)
        } else {
            console.log("error")
        }

    }

    const cancelSubscriptionHandle = async (priceId) => {

        if (!isEmpty(customer.id) && !isEmpty(priceId)) {
            setWait(true);
            let paymentResult = await cancelSubscription(customer.id, priceId);

            if (paymentResult.result) {
                dispatch(getCustomer());

                dispatch(toggleModal(
                    {
                        opened: true,
                        success: true,
                        text: "Subscribe success"
                    })
                )
            } else {
                dispatch(toggleModal(
                    {
                        opened: true,
                        success: false,
                        text: "Subscribe error"
                    })
                )
            }
            setWait(false)
        } else {
            console.log("error")
        }
    };

    return (
        <Fragment>
            {openEditPayment && (
                <EditPayment
                    onClose={() => setOpenEditPayment(!openEditPayment)}
                    onSuccess={onChangePlan}
                    setAsDefault={true}
                />
            )}

            {openModal && (
                <PriceSelector
                    wait={wait}
                    prices={prices}
                    setCheckedPriceId={setCheckedPriceId}
                    closeModal={() => setOpenModal(!openModal)}
                    onChangePlan={onChangePlan}
                />
            )}

            <div className="grid">
                {customerPlan ? (
                    <div>
                        <h3>Votre abonnement</h3>
                        <div className="element active-plan p-3">
                            <div className="d-flex justify-content-space-between">
                                <span className="badge" style={customerPlan.plan_active !== "1" ? {background: "red"} : {}}>
                                    {customerPlan.plan_active === "1" ? "Plan Actuel" : "Plan Not Active"}
                                </span>
                                <h2 className="my-auto mx-2">{!isEmpty(customerPlan?.product?.name) ? customerPlan.product.name : ""}</h2>
                                <img src="/img/icons/delete.svg" className="cursor-pointer" alt="" onClick={() => cancelSubscriptionHandle(customerPlan.id)}/>
                            </div>
                            <div>
                                <span className="price">{(customerPlan.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})} / {customerPlan.type === "recurring" ? customerPlan.recurring.interval : "one-time"}</span>
                            </div>
                            <div>
                                <p className="my-auto">{!isEmpty(customerPlan?.product?.description) ? customerPlan.product?.description : ""}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h3>Votre abonnement</h3>
                        <div className="element active-plan p-3">
                            You don't have an active subscribe.
                        </div>
                    </div>
                )}

                <div>
                    <h3>Take it to the next level</h3>
                    {products && products.map((product) => {
                        if (isEmpty(customerPlan) || (!isEmpty(customerPlan) && product.id !== customerPlan.product)) {
                            return (
                                <div className="element plans" key={product.id} id={product.id}>

                                    <div className="d-flex">
                                        <div className="checkbox my-auto mx-2">
                                            <Checkbox
                                                checked={checkedPlanId === product.id}
                                                onChange={() => setCheckedPlanId((prev) => prev === product.id ? null : product.id)}
                                                borderColor="#4f49a1"
                                                borderWidth="1px"
                                                containerClassName="d-block"
                                                icon={
                                                    <svg fill="#4caf50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>
                                                }
                                            />
                                        </div>
                                        <div className="content">
                                            <h2 className="my-2">{product.name}</h2>
                                            <p className="mb-2">{product.description}</p>
                                        </div>
                                    </div>

                                    <div className="price">
                                        {!isEmpty(product.prices) && product.prices.sort((a, b) => !isEmpty(b?.recurring?.interval) && !isEmpty(a?.recurring?.interval) ? a.recurring.interval < b.recurring.interval ? -1 : 0 : 0).map(price => {
                                            return (
                                                <span key={price.id} className={`${price?.recurring?.interval} my-2`}>{(price.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}<br/>{!isEmpty(price?.recurring?.interval) ? price.recurring.interval : "one-time"}</span>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        } else {
                            return false;
                        }

                    })}

                    <button
                        disabled={isEmpty(checkedPlanId)}
                        className="btn btn-stripe mt-2"
                        onClick={() => setOpenModal(!openModal)}
                        style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                    >Subscribe</button>
                </div>
            </div>
        </Fragment>
    );
}

export default Plans;