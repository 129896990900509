import React, {useContext, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {isEmpty} from "../components/Utils";
import {Redirect, useHistory} from "react-router-dom";
import {getVideo, getVideoFavorite, getVideosFrom} from "../actions/videos.action";
import Filter from "../components/videos/Filter";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from 'axios';
import Video from "../components/Video";
import {getPopularVideoPlaylist, getRecentVideoPlaylist} from "../actions/playlist.action";
import {MyContext} from "../contexts/MyContext";
import {toggleModal} from "../actions/modal.action";

const FavoriteVideos = () => {
	const { rootState, addToVideoFavorite } = useContext(MyContext);
	const { isAuth, redirect } = rootState;
	const dispatch = useDispatch();
	const history = useHistory();
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const [videos, setVideos] = useState(null);
	const [disciplineSelect, setDisciplineSelect] = useState("");
	const [devicesSelect, setDevicesSelect] = useState("");
	const [regionSelect, setRegionSelect] = useState("");
	const [scrollPage, setScrollPage] = useState(0);
	const [state, setState] = useState(null);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;

	const videosFavoritesReducer = useSelector(
		(state) => state.videosReducer.getVideoFavoriteReducer
	);

	useEffect(() => {
		async function auth() {
			if (isAuth) {
				localStorage.removeItem('loginRedirectUrl');
				dispatch(getVideoFavorite("0", "3", "10"));
			} else {
				localStorage.setItem('loginRedirectUrl', window.location);
			}
			if (redirect !== null) setState(!redirect);
		}
		auth();
	}, [isAuth, redirect]);

	useEffect(() => {
		if (!isEmpty(videosFavoritesReducer)) {
			setVideos(videosFavoritesReducer.videos)
		}
	}, [videosFavoritesReducer]);

	const clickBack = () => {
		history.push(`/`);
	};

	const onSelectFilter = (discipline, device, region) => {
		setDisciplineSelect(discipline);
		setDevicesSelect(device);
		setRegionSelect(region);
		setScrollPage(0);
		dispatch(getVideoFavorite("0", "3", "10"));
	};

	const fetchVideos = async () => {
		let incrementPage = scrollPage + 1;
		setScrollPage(incrementPage);

		const loginToken = localStorage.getItem("loginToken");
		if (loginToken) {
			let form = new FormData();
			form.append("key", loginToken);
			form.append("p", incrementPage.toString());
			form.append("t", "3");
			form.append("n", "10");
			await axios.post(`${process.env.REACT_APP_API_URL}getVideoFavorite`, form)
				.then((res) => {
					setVideos([...videos, ...res.data.videos])
				})
				.catch((error) => {
					console.log(error)
				});
		}
	};

	const handleFavorite = async (id, keyArray) => {

		await addToVideoFavorite(id)
			.then((res) => {
				if (res.result === "ok" && !isEmpty(videos)) {
					const arrayClone = Array.from(videos);
					arrayClone.splice(keyArray, 1)
					setVideos(arrayClone);

					let text = translations && !isEmpty(translations["video_removed_from_list"])
						? translations["video_removed_from_list"]
						: "Your video has been removed from your list!"

					dispatch(toggleModal(
						{
							opened: true,
							success: true,
							text: text
						})
					)
					if (isAuth) {
						dispatch(getVideoFavorite("0", "3", "10"))
					}
				}
			})
	};

	return (
		<>
			{state === false && <Redirect to="/account/login"/>}
			<main className="discipline-page">
				<div className="discipline-title">
					<div className="title" style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
						<h1>
							<span onClick={() => clickBack()}/>
							{translations && !isEmpty(translations["my_videos"])
								? translations["my_videos"]
								: "My Videos"}
						</h1>
					</div>
					<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="20%" height="2" fill="#1B3FFF"/>
						<rect x="20%" width="20%" height="2" fill="#D43D17"/>
						<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
						<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
						<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
					</svg>
				</div>

				{/*<Filter
				 onSelectFilter={onSelectFilter}
				 />*/}

				<div className="content">
					{!isEmpty(videos) &&
						<InfiniteScroll
							dataLength={videos.length}
							next={fetchVideos}
							hasMore={true}
							style={{overflow: 'hidden'}}
							//loader={"<span>Wait</span>"}
						>
							{videos.map((video) => {
								return (
									<Video video={video} key={video.id} handleFavorite={handleFavorite}/>
								)
							})}
						</InfiniteScroll>
					}
				</div>

			</main>
		</>
	);
};

export default FavoriteVideos;
