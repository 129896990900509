import { combineReducers } from "redux";
import {
	GET_CUSTOMER,
	GET_LIST_PAYMENTS,
	GET_PRODUCT,
	GET_INVOICES,
	GET_PRODUCTS,
	GET_ACTIVE_CUSTOMER_PRODUCT,
	GET_PAYMENTS_INTENTS
} from "../actions/stripe.action";
const initialState = [];

function getCustomerReducer(state = initialState, action) {
	switch (action.type) {
		case GET_CUSTOMER:
			return action.payload;
		default:
			return state;
	}
}

function getListPaymentsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_LIST_PAYMENTS:
			return action.payload;
		default:
			return state;
	}
}

function getProductReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PRODUCT:
			return action.payload;
		default:
			return state;
	}
}

function getProductsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PRODUCTS:
			return action.payload;
		default:
			return state;
	}
}

function getActiveCustomerProductReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ACTIVE_CUSTOMER_PRODUCT:
			return action.payload;
		default:
			return state;
	}
}

function getInvoicesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_INVOICES:
			return action.payload;
		default:
			return state;
	}
}

function getPaymentsIntentsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PAYMENTS_INTENTS:
			return action.payload;
		default:
			return state;
	}
}


export default combineReducers({
	getCustomerReducer,
	getListPaymentsReducer,
	getProductReducer,
	getInvoicesReducer,
	getProductsReducer,
	getActiveCustomerProductReducer,
	getPaymentsIntentsReducer
});
