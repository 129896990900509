import React, { useContext, useEffect, useState } from "react";
import {Link, NavLink, useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { MyContext } from "../../contexts/MyContext";
import { isEmpty } from "../Utils";
import {
	toggleNotification
} from "../../actions/account.action";
import { getNotifications } from "../../actions/notifications.action";
import LanguagesSelection from "./LanguagesSelection";
import {useLocation} from "react-router";
import {useMediaQuery} from "react-responsive";

const AccountSection = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const { rootState, logoutUser } = useContext(MyContext);
	const { isAuth, theUser } = rootState;
	const [menuState, setMenuState] = useState(false);
	const [countNotification, setCountNotification] = useState("0");
	const [showSearch, setShowSearch] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
	const settings = useSelector(
		(state) => state.settingReducer.getSettingReducer.config
	);
	const [styles, setStyles] = useState({});
	const [hoverReg, setHoverReg] = useState(false);
	const [hoverLog, setHoverLog] = useState(false);

	const [boxStyleLog, setBoxStyleLog] = useState({});
	const [boxStyleReg, setBoxStyleReg] = useState({});

	useEffect(() => {
		if (settings?.menu_text_color) {
			setBoxStyleLog((prevState) => ({...prevState, backgroundColor: hoverLog && settings?.menu_text_color ? settings.menu_text_color : ""}));
			setBoxStyleLog((prevState) => ({...prevState, color: hoverLog ? "" : (settings?.menu_text_color ? settings.menu_text_color : "")}));
			setBoxStyleLog((prevState) => ({...prevState, borderColor: settings?.menu_text_color ? settings.menu_text_color : ""}));
		}
	}, [hoverLog, settings]);

	useEffect(() => {
		if (settings?.menu_text_color) {
			setBoxStyleReg((prevState) => ({...prevState, backgroundColor: !hoverReg  && settings?.menu_text_color ? settings.menu_text_color : ""}));
			setBoxStyleReg((prevState) => ({...prevState, color: !hoverReg ? "" : (settings?.menu_text_color ? settings.menu_text_color : "")}));
			setBoxStyleReg((prevState) => ({...prevState, borderColor: settings?.menu_text_color ? settings.menu_text_color : ""}));
		}
	}, [hoverReg, settings]);


	const notificationsReducer = useSelector(
		(state) => state.notificationsReducer
	);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);

	useEffect(() => {
		if (settings?.menu_text_color) {
			setStyles((prevState) => ({...prevState, color: settings.menu_text_color}));
		}
	}, [settings]);

	useEffect(() => {
		dispatch(getNotifications());
	}, [dispatch, isAuth]);

	useEffect(() => {
		if (notificationsReducer.result === "ok") {
			setCountNotification(notificationsReducer.unread);
		} else {
			setCountNotification(0);
		}
	}, [notificationsReducer]);

	const notificationToggleReducer = useSelector(
		(state) => state.accountReducer.notificationReducer
	);

	const handleClickOutside = (event) => {
		if (event.target.closest('.account') === null) {
			setMenuState(false);
		}
	};

	useEffect(() => {
		showSearch && setShowSearch(false)
		// eslint-disable-next-line
	}, [location]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return (
		<div className="account-section">

			{!isMobile && (
				<LanguagesSelection />
			)}

			<div className="search-button">
				{showSearch &&
				<div className="search-container">
					<section className="search-section">
						<h4>
							{translations && !isEmpty(translations["search"])
								? parse(translations["search"])
								: "Search"
							}
						</h4>
						<form>
							<span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
								</svg>

								<input
									type="text"
									name="search"
									onKeyDown={(e) => e.keyCode === 13 ? history.push(`/search/${encodeURI(searchInput)}`) : ''}
									onInput={(e) => setSearchInput(e.target.value)}
								/>
								<Link to={`/search/${encodeURI(searchInput)}`} className="button-submit">Search</Link>

							</span>
						</form>
						<div
							className="close"
							onClick={() => setShowSearch(false)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
							</svg>
						</div>
					</section>
				</div>
				}
				<svg
					onClick={() => setShowSearch(!showSearch)}
					xmlns="http://www.w3.org/2000/svg"
					width="40"
					height="40"
					viewBox="0 0 40 40"
				>
					<path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" fill={settings?.menu_text_color ? settings.menu_text_color : "white"}/>
				</svg>
			</div>

			{!isMobile && isAuth && (
				<div
					className="notifications"
					onClick={() => dispatch(toggleNotification(!notificationToggleReducer))}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
					>
						<path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" fill={settings?.menu_text_color ? settings.menu_text_color : "white"}/>
					</svg>
					<div className="notifications-number">
						<span>{countNotification ? countNotification : "0"}</span>
					</div>
				</div>
			)}

			{!isMobile && (
				<div className="account">
					{!isAuth && (
						<div className="buttons">
							<NavLink to="/account" className="not-button">
								<div className="login mobile-menu">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.707 13.946l-1.035 1.054h-.672v1h-1v1h-3v-2.292l3.146-3.185c.496 1.111 1.419 1.988 2.561 2.423zm5.293-4.279c0 2.025-1.642 3.667-3.667 3.667-2.024 0-3.666-1.642-3.666-3.667s1.642-3.667 3.666-3.667c2.025 0 3.667 1.642 3.667 3.667zm-1.375-1.375c0-.506-.41-.917-.917-.917s-.916.411-.916.917.409.917.916.917.917-.411.917-.917z" fill={settings?.menu_text_color ? settings.menu_text_color : "white"}/>
									</svg>
								</div>
							</NavLink>

							<NavLink to="/account/register" id="register-button" style={boxStyleReg} onMouseEnter={() => setHoverReg(!hoverReg)} onMouseLeave={() => setHoverReg(!hoverReg)}>
								{translations && !isEmpty(translations["button_register"])
									? translations["button_register"]
									: "Register"
								}
							</NavLink>

							<NavLink to="/account/login" id="login-button" style={boxStyleLog} onMouseEnter={() => setHoverLog(!hoverLog)} onMouseLeave={() => setHoverLog(!hoverLog)}>
								{translations && !isEmpty(translations["button_login"])
									? translations["button_login"]
									: "Login"
								}
							</NavLink>
						</div>
					)}
					{isAuth && (
						<div className="account-menu" onClick={() => setMenuState(!menuState)}>
							<img
								src={!isEmpty(theUser.img.avatar) ? theUser.img.avatar : "/img/icons/user.svg"}
								alt="account"
								width="30"
							/>
							<span style={styles}>{theUser.username}</span>

							<svg className="ml-1" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24">
								<path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" fill={settings?.menu_text_color ? settings.menu_text_color : "white"}/>
							</svg>

							<div className={ menuState ? "hover sub-menu" : "sub-menu" }>
								<ul style={{background: !isEmpty(settings) ? settings.accent_color : ""}}>
									<li
										onClick={() => {
											dispatch(toggleNotification(!notificationToggleReducer));
										}}
									>
										<div
											className="notifications"
										>
											<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" clipRule="evenodd" d="M8.125 2.76317C4.8902 3.60357 2.5 6.56783 2.5 10.0984V15.1473C2.5 15.6125 2.12572 15.9896 1.66754 15.9896C0.746581 15.9896 0 16.7451 0 17.6727C0 18.6023 0.747522 19.3558 1.67079 19.3558H18.3292C19.252 19.3558 20 18.6004 20 17.6727C20 16.7432 19.2499 15.9896 18.3325 15.9896C17.8727 15.9896 17.5 15.6154 17.5 15.1473V10.0984C17.5 6.56923 15.1103 3.60386 11.875 2.7632V1.89387C11.875 0.84545 11.0355 0 10 0C8.96657 0 8.125 0.847913 8.125 1.89387V2.76317ZM7.08333 21.0545H12.9167C12.9167 22.6813 11.6108 24 10 24C8.38917 24 7.08333 22.6813 7.08333 21.0545Z" fill="white"/>
											</svg>

											<div className="notifications-number">
												<span>{countNotification ? countNotification : "0"}</span>
											</div>
											<span>
											{translations
												? translations["notifications"]
												: "notifications"}
											</span>
										</div>
									</li>
									<li>
										<NavLink to="/settings">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
											>
												<path d="M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z"/>
											</svg>
											<span>
												{translations && !isEmpty(translations["menu_settings"])
													? translations["menu_settings"]
													: "Settings"
												}
											</span>
										</NavLink>
									</li>
									<li
										onClick={() => {
											logoutUser();
										}}
									>
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M20 10L13.3333 4.94465V7.77777H5.55556V12.2222H13.3333V15.0165L20 10ZM2.22222 2.22223H11.1111V0H2.22222C1 0 0 1.00001 0 2.22223V17.7778C0 19 1 20 2.22222 20H11.1111V17.7778H2.22222V2.22223Z" fill="white"/>
										</svg>
										<span>
										{translations && !isEmpty(translations["logout"])
											? translations["logout"]
											: "Logout"
										}
									</span>
									</li>
								</ul>
							</div>
						</div>
					)}
				</div>
			)}

		</div>
	);
};

export default AccountSection;
