import {
    useStripe,
    useElements,
    CardNumberElement, CardExpiryElement, CardCvcElement
} from '@stripe/react-stripe-js';
import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {MyContext} from "../contexts/MyContext";
import {getListPaymentsMethods} from "../actions/stripe.action";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty, isNumeric} from "./Utils";
import {toggleModal} from "../actions/modal.action";
import Select from "react-select";

const CheckoutForm = ({entityType, entityId, onUpdate, price, customerId}) => {

    const stripeReducer = useSelector((state) => state.stripeReducer);
    const payments = stripeReducer.getListPaymentsReducer;

    const { createPaymentIntent, rootState } = useContext(MyContext);
    const { isAuth, theUser } = rootState;
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [typeForPay, setTypeForPay] = useState("myCards");
    const [priceType, setPriceType] = useState(entityType)
    const [selectedEntityId, setSelectedEntityId] = useState(entityId)
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getListPaymentsMethods(customerId));
    }, [customerId, dispatch])

    useEffect(() => {
        if (payments && payments.length === 0) {
            setTypeForPay("newCard");
        }
    }, [payments]);

    useEffect(() => {
        setPaymentMethod(null)
    }, [typeForPay]);

    const options = {
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#fff",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ':-webkit-autofill': {
                    color: "#fce883",
                },
                '::placeholder': {
                    color: "rgba(255, 255, 255, 0.6)",
                },
            }
        }
    }

    const handlePayClick = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        if (isAuth) {
            onUpdate("wait");
            if (isEmpty(paymentMethod)) {
                const result = await stripe.createPaymentMethod({
                    type: "card",
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        email: theUser.email
                    }
                });

                if (result.error) {
                    dispatch(toggleModal({opened: true, success: false, text: result.error.message}));
                    onUpdate(false);
                } else {
                    setPaymentMethod(result.paymentMethod.id);
                    await payHandle(result.paymentMethod.id)
                }
            } else {
                await payHandle(paymentMethod)
            }
        }

    };

    const payHandle = async (paymentId) => {
        let paymentResult = await createPaymentIntent(customerId, paymentId, priceType, selectedEntityId);

        if (!isEmpty(paymentResult) && paymentResult.result !== "bad" && paymentResult.response.status === "succeeded") {
            onUpdate(false);
            dispatch(toggleModal(
                {
                    opened: true,
                    success: true,
                    text: "Payment success"
                })
            )
            setTimeout(() => {
                window.location.reload();
            }, 1000);

        } else {
            dispatch(toggleModal(
                {
                    opened: true,
                    success: false,
                    text: "Payment error"
                })
            )
            setPaymentMethod(null)
            onUpdate(false);
        }
    }

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor: "pointer",
            borderRadius: "5px",
            border: "1px solid #8d8d8d",
        }),
        option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
            ...provided,
            color: isDisabled ? undefined : isSelected ? "#ffffff" : isFocused ? "black" : undefined,
            cursor: "pointer",
            backgroundColor: isDisabled ? undefined : isSelected ? "#49546c" : isFocused ? "white" : undefined,
            ":hover": {
                ...provided[":hover"],
                color: isSelected ? "#ffffff" : "black"
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderStyle: "none",
            borderWidth: "unset",
            minHeight: "2.5rem",
            color: "#ffffff",
            boxShadow: "none",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#000000",
            fontSize: "1.6rem"
        }),
        menu: (provided, state) => ({
            ...provided,
            color: "#ffffff",
            backgroundColor: "#1b283c",
            marginTop: 0,
            borderRadius: "0 0 4px 4px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    };

    const priceOptions = useMemo(() => {
        let prices = [];
        if (isNumeric(price.entity.price)) {
            prices.push({label: `For video ${(price.entity.price / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}`, value: entityType, dataId: price.entity.entity_id});
        }
        if (isNumeric(price.playlist.price)) {
            prices.push({label: `For playlist ${(price.playlist.price / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'})}`, value: "playlist", dataId: price.playlist.entity_id});
        }
        return prices;
    }, [price, entityType]);

    useEffect(() => {
        onChangeOption(priceOptions[0])
    }, [priceOptions]);

    const onChangeOption = (option) => {
        setPriceType(option.value);
        setSelectedEntityId(option.dataId)
    };

    return (
        <Fragment>
            <div>
                <Select
                    id="entity_type"
                    styles={customStyles}
                    defaultValue={priceOptions[0]}
                    options={priceOptions}
                    onChange={(option) => onChangeOption(option)}
                />
            </div>

            <div className="mt-1">
                <button className={`button button-blue small mr-2 ${typeForPay === "newCard" ? "selected" : ""}`} onClick={() => setTypeForPay("newCard")}>New Card</button>

                {payments && payments.length > 0 && (
                    <button className={`button button-blue small ${typeForPay === "myCards" ? "selected" : ""}`} onClick={() => setTypeForPay("myCards")}>My Cards</button>
                )}
            </div>


            {typeForPay === "myCards" && (
                <div>
                    {payments.map((elem) => {
                        return (
                            <div className={`cursor-pointer method ${paymentMethod === elem.id ? "selected" : ""}`} key={elem.id} onClick={() => setPaymentMethod((elem.id))}>
                                <div className="card d-flex my-1">
                                    <div className="image ml-1">
                                        <img src={`/img/icons/cards/${elem.card.brand}.svg`} alt=""/>
                                    </div>
                                    <div className="my-auto mx-2 d-grid">
                                        <span className="number">{elem.card.brand} *** {elem.card.last4}</span>
                                        <span className="expire mt-2">Expire le {elem.card.exp_month < 10 ? `0${elem.card.exp_month}` : elem.card.exp_month}/{elem.card.exp_year}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}

            {typeForPay === "newCard" && payments && (
                <div>
                    <CardNumberElement options={{...options, iconStyle: "solid", showIcon: true}}/>
                    <CardExpiryElement options={options}/>
                    <CardCvcElement options={options}/>
                </div>
            )}

            <button
                className="btn-stripe mt-1"
                disabled={isEmpty(paymentMethod) && typeForPay !== "newCard"}
                onClick={handlePayClick}
            >Pay</button>

        </Fragment>
    )
};

export default CheckoutForm;