import videojs from 'video.js';
import {isNumeric} from "../../components/Utils";

const Plugin = videojs.getPlugin('plugin');

class Subscription extends Plugin {
    constructor(player, options) {
        super(player, options);
        this.videoEl = player.el();
        this.default = {
            can_view: null,
            entity: null
        };
        this.state = this.default;
        this.on("statechanged", this.stateChanged);
    }


    updateState = (settings) => {
        let {can_view, entity} = settings;

        this.setState({
            can_view,
            entity
        });
    };

    stateChanged = () => {

        if (this.state.can_view === "no" || this.state.can_view === '0') {
            this.stopPlayer();
        } else {
            if (this.state.can_view === "yes") {
                this.playPlayer();
            } else if (isNumeric(this.state.can_view)) {
                this.player.on("timeupdate", this.startDurationListener);
            }
        }

    };

    startDurationListener = () => {
        let percentOfVideo = this.player.currentTime() / this.player.duration() * 100;

        if (this.state.entity === "video" && isNumeric(this.state.can_view)) {
            let can_view = this.state.can_view;
            let maxViewCurrentTime =  this.player.duration() * can_view / 100;

            if (percentOfVideo >= can_view) {
                this.player.currentTime(maxViewCurrentTime)
                this.stopPlayer();
            }

        } else {
            //disabled for live because have to think how to make limit
            //can_view = this.state.customer_live_duration;
            this.stopPlayer();
        }
    };

    playPlayer = () => {
        this.player.trigger("needToPay", false);
        //this.player.off("timeupdate", this.startDurationListener);
    }

    stopPlayer = () => {
        this.player.trigger("needToPay", true);
        //this.player.off("timeupdate", this.startDurationListener);
    };

    dispose() {
        super.dispose();
    }

    reset() {
        this.setState(this.default);
    }
}

videojs.registerPlugin('subscription', Subscription);