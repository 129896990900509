import {isEmpty} from "../components/Utils";
import ReCAPTCHA from "react-google-recaptcha";
import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {MyContext} from "../contexts/MyContext";
import axios from "axios";
import {NavLink} from "react-router-dom";

const Delete = () => {
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;
	const [inputsStyle, setInputsStyle] = useState({});
	const [captchaValue, setCaptchaValue] = useState(null);
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [passwordVerify, setPasswordVerify] = useState(null);
	const { rootState, logoutUser } = useContext(MyContext);
	const { isAuth } = rootState;
	const [modalWindow, setModalWindow] = useState(undefined);

	useEffect(() => {
		setInputsStyle(prevState => ({...prevState, '--placeholder-color': (settings?.general_text_color ? settings.general_text_color : "rgba(255, 255, 255, 0.6)")}));
		setInputsStyle(prevState => ({...prevState, color: (settings?.general_text_color ? settings.general_text_color : "rgba(157, 157, 157, 1)")}));
	}, [settings]);

	const sendDelete = async (event) => {
		event.preventDefault();
		if (password === passwordVerify && captchaValue && email) {
			let form = new FormData();
			form.append("l", email);
			form.append("p", password);
			form.append("c", captchaValue);

			let result = await axios.post(
				`${process.env.REACT_APP_API_URL}delete`, form
			);

			if (result?.data?.result === "ok") {
				setModalWindow(1);

				if (isAuth) {
					logoutUser();
				}
				window.location.href = "/";
			} else {
				setModalWindow(2);
			}

			setCaptchaValue(null);
		}
	};

	const onChangeCaptcha = (value) => {
		setCaptchaValue(value);
	};


	return (
		<div className="account-page">

			{modalWindow && (
				<div className="confirm-message display">
					<div className="svg"
					     style={{backgroundColor: modalWindow === 2 ? "rgb(230, 36, 36)" : ""}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/>
						</svg>
					</div>

					{modalWindow === 2 ? (
						<h2>{translations && !isEmpty(translations["error"]) ? translations["error"] : "Error"}</h2>
					) : (
						<h2>{translations && !isEmpty(translations["success"]) ? translations["success"] : "Success"}</h2>
					)}

					<NavLink to="/account/login">
						<span>{translations && !isEmpty(translations["close"]) ? translations["close"] : "close"}</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
						</svg>
					</NavLink>
				</div>
			)}


			<section className="account">
				<div className="account-container">
					<h2 className="border untouch">
						{translations && !isEmpty(translations["delete_account"])
							? translations["delete_account"]
							: "delete_account"}
					</h2>
					<form onSubmit={sendDelete} noValidate>
						<div className="input select">
							<input
								type="email"
								name="email"
								id="email"
								style={inputsStyle}
								placeholder={
									translations && !isEmpty(translations["register_email_ph"])
										? translations["register_email_ph"]
										: "Email"
								}
								required
								autoFocus
								autoComplete="email"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="input select">
							<input
								type="password"
								name="password"
								id="password"
								style={inputsStyle}
								placeholder={
									translations && !isEmpty(translations["register_password_ph"])
										? translations["register_password_ph"]
										: "register_password_ph"
								}
								required
								autoFocus
								autoComplete="new-password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className={`input select ${password !== passwordVerify ? "error" : ""}`}>
							<input
								type="password"
								name="passwordConfirm"
								id="passwordConfirm"
								style={inputsStyle}
								placeholder={
									translations && !isEmpty(translations["register_password_confirm_ph"])
										? translations["register_password_confirm_ph"]
										: "register_password_confirm_ph"
								}
								required
								autoFocus
								autoComplete="new-password"
								onChange={(e) => setPasswordVerify(e.target.value)}
							/>
						</div>
						<div style={{display: captchaValue ? "none" : ""}}>
							<ReCAPTCHA
								sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
								onChange={onChangeCaptcha}
							/>
						</div>
						<input
							style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
							hidden={!captchaValue}
							type="submit"
							name="submit"
							id="submit"
							value={translations && !isEmpty(translations["delete_account"])
								? translations["delete_account"]
								: "delete_account"
							}
						/>
					</form>
				</div>
			</section>
			<div className="banner">
				<img
					src={!isEmpty(settings) && !isEmpty(settings.login_image) ?
						`${process.env.REACT_APP_CACHE_SERVER}/images${settings.login_image}` :
						`/img/banner-account.png`} alt="banner account"
					onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						currentTarget.src = "/img/banner-account.png"
					}}
				/>
			</div>
		</div>
	)
};

export default Delete;