import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {getChildPrograms, getPrograms} from "../actions/programs.action";
import {isEmpty} from "../components/Utils";
import {getVideosFrom} from "../actions/videos.action";
import axios from "axios";
import {Helmet} from "react-helmet-async";
import Filter from "../components/programme/Filter";
import InfiniteScroll from "react-infinite-scroll-component";
import Video from "../components/Video";

const Categories = () => {
	const { programId, childId } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
	const videosReducer = useSelector((state) => state.videosReducer.getVideosFromReducer);
	const [videos, setVideos] = useState(null);
	const [devicesSelect, setDevicesSelect] = useState("");
	const [regionSelect, setRegionSelect] = useState("");
	const [disciplineSelect, setDisciplineSelect] = useState("");
	const [scrollPage, setScrollPage] = useState(0);
	const [hasNextPage, setHasNextPage] = useState(true);
	const programReducer = useSelector(
		(state) => state.programReducer.getProgramsReducer
	);

	const programChildReducer = useSelector(
		(state) => state.programReducer.getProgramsChildReducer
	);

	const [selectParentProgram, setSelectParentProgram] = useState({
		id: "",
		name: "",
		name_web: "",
		childs: 0
	});

	const [selectChildProgram, setSelectChildProgram] = useState({
		id: "",
		name: "",
		name_web: "",
		childs: 0,
		pic: ""
	});

	useEffect(() => {
		dispatch(getPrograms(20));
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(selectParentProgram.id) && selectParentProgram.childs !== "0") {
			dispatch(getChildPrograms(20, selectParentProgram.id));
		}
		// eslint-disable-next-line
	}, [selectParentProgram]);

	useEffect(() => {
		if ((!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id)) || (!isEmpty(selectParentProgram) && !isEmpty(selectParentProgram.id))) {
			dispatch(getVideosFrom("program", (!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id)) ? selectChildProgram.id : selectParentProgram.id));
		}
		// eslint-disable-next-line
	}, [selectChildProgram, selectParentProgram]);

	useEffect(() => {
		if (!isEmpty(videosReducer)) {
			setVideos(videosReducer.videos)
		}
	}, [videosReducer]);

	useEffect(() => {
		if (!isEmpty(programReducer.programs) && !isEmpty(programId)) {
			let select = programReducer.programs.filter((prog) => {
				return programId === prog.name_web.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_")
			});
			setSelectParentProgram(...select);
		}
	}, [programId, programReducer.programs]);


	useEffect(() => {
		if (!isEmpty(programChildReducer.programs) && !isEmpty(programId)) {
			let select = programChildReducer.programs.filter((prog) => {
				return childId === prog.name_web.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_")
			});
			setSelectChildProgram(...select);
		}
	}, [childId, programChildReducer.programs, programId]);

	const clickBack = () => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.push(`/`);
		}
	};

	const onSelectFilter = (disciplineSelect, devicesSelect, region) => {
		setHasNextPage(true);
		setScrollPage(0);
		dispatch(getVideosFrom("program", !isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id) ? selectChildProgram.id : selectParentProgram.id, disciplineSelect, devicesSelect, region));
		setDisciplineSelect(disciplineSelect);
		setDevicesSelect(devicesSelect);
		setRegionSelect(region);
	};

	const fetchVideos = async () => {
		let incrementPage = scrollPage + 1;
		setScrollPage(incrementPage);
		await axios.get(`${process.env.REACT_APP_API_URL}getvideosfrom&f=program&i=${!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id) ? selectChildProgram.id : selectParentProgram.id}&di=${disciplineSelect}&de=${devicesSelect}&re=${regionSelect}&p=${incrementPage}&n=20`)
			.then((res) => {
				let responseVideos = res.data.videos;
				setVideos([...videos, ...responseVideos]);
				setHasNextPage(res.data.nextpage !== null);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	if (selectParentProgram.childs === "0" || (!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id))) {
		return (
			<>
				<Helmet>
					<meta name="title" content={`${process.env.REACT_APP_CLIENT_NAME} - ${!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id) ? (selectChildProgram[isMobile ? "name" : "name_web"]) : (selectParentProgram[isMobile ? "name" : "name_web"])}`} />
					<meta property="og:title" content={`${process.env.REACT_APP_CLIENT_NAME} - ${!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id) ? (selectChildProgram[isMobile ? "name" : "name_web"]) : (selectParentProgram[isMobile ? "name" : "name_web"])}`} />
					<meta property="og:description" content={`${process.env.REACT_APP_CLIENT_NAME} - ${!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id) ? (selectChildProgram[isMobile ? "name" : "name_web"]) : (selectParentProgram[isMobile ? "name" : "name_web"])}`} />
					<meta property="og:image" content={`${!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id) ? (selectChildProgram.pic) : (selectParentProgram.pic)}`} />
				</Helmet>

				<main className="discipline-page">
					<div className="discipline-title">
						<div className="title">
							<h1>
								<span onClick={() => clickBack()}/>
								{!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id) ? (selectChildProgram[isMobile ? "name" : "name_web"]) : (selectParentProgram[isMobile ? "name" : "name_web"])}
							</h1>
						</div>
						<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect width="20%" height="2" fill="#1B3FFF"/>
							<rect x="20%" width="20%" height="2" fill="#D43D17"/>
							<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
							<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
							<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
						</svg>
					</div>

					<Filter
						onSelectFilter={onSelectFilter}
						programmeId={!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id) ? (selectChildProgram.id) : (selectParentProgram.id)}
					/>

					<div className="content">
						{!isEmpty(videos) &&
						<InfiniteScroll
							dataLength={videos.length}
							next={fetchVideos}
							hasMore={hasNextPage}
							style={{ overflow: 'hidden' }}
						>
							{videos.map((video) => {
								return (
									<Video video={video} key={video.id}/>
								)
							})}
						</InfiniteScroll>
						}
					</div>
				</main>
			</>
		);
	} else {
		return (
			<>
				<Helmet>
					<title>{process.env.REACT_APP_CLIENT_NAME}</title>
					<meta name="title" content={process.env.REACT_APP_CLIENT_NAME} />
					<meta property="og:title" content={process.env.REACT_APP_CLIENT_NAME} />
					<meta property="og:description" content={process.env.REACT_APP_CLIENT_NAME} />
				</Helmet>

				<main className="categories-page">
					<div className="discipline-title">
						<div className="title">
							<h1>
								<span onClick={() => clickBack()}/>
								{!isEmpty(selectChildProgram) && !isEmpty(selectChildProgram.id) ? (
									selectChildProgram[isMobile ? "name" : "name_web"]
								) : (
									selectParentProgram[isMobile ? "name" : "name_web"]
								)}
							</h1>
						</div>
						<svg height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect width="20%" height="2" fill="#1B3FFF"/>
							<rect x="20%" width="20%" height="2" fill="#D43D17"/>
							<rect x="40%" width="20%" height="2" fill="#4DAEC9"/>
							<rect x="60%" width="20%" height="2" fill="#ECC24A"/>
							<rect x="80%" width="20%" height="2" fill="#9AB94F"/>
						</svg>
					</div>

					<div className="categories">
						{!isEmpty(programChildReducer.programs) &&
						programChildReducer.programs.map((category, index) => {
							return (
								<Link
									key={category.id}
									to={`/categories/${programId}/${category.name_web.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "_")}`}
									onClick={() => window.scrollTo(0, 0)}
								>
									<div className="category" key={category.id}>
										<div className="image_container">
											<img src={category.pic} alt={isMobile ? category.name : category.name_web}/>
										</div>

										<div className="title">
											<h4>{isMobile ? category.name : category.name_web}</h4>
										</div>
										<div className="play-button">
											<svg className="btn" width="45" height="45" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18Z" fill="url(#paint0_radial_1332_2732)"/>
												<path className="play_sign" d="M24.8276 17.3793L13.6552 23.8297L13.6552 10.9289L24.8276 17.3793Z" fill="white"/>
												<defs>
													<radialGradient id="paint0_radial_1332_2732" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18 18) rotate(90) scale(18)">
														<stop stopColor="#4E45AF"/>
														<stop offset="1" stopColor="#362CAE"/>
													</radialGradient>
												</defs>
											</svg>
										</div>
									</div>
								</Link>
							)
						})
						}
					</div>

				</main>
			</>
		);
	}

};

export default Categories;
