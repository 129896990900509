import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import Slider from "react-slick";
import RightArrow from "./slider/RightArrow";
import LeftArrow from "./slider/LeftArrow";
import {isEmpty} from "./Utils";
import {useSelector} from "react-redux";
import axios from "axios";
import {useMediaQuery} from "react-responsive";

const Events = (props) => {
	const { events, showMore, title, nextPage} = props;
	const eventRef = useRef(null);
	const slider = useRef(null);
	const [width, setWidth] = useState();
	const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
	const settingsOtt = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const [eventsArray, setEventsArray] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(5);
	const [hasNexPage, setHasNextPage] = useState(nextPage);
	const [countPage, setCountPage] = useState(1);
	const translationsReducer = useSelector(
		(state) => state.langReducer.translationsReducer
	);
	const translations = translationsReducer.translate;
	const [styles, setStyles] = useState({});

	const getWidth = () => {
		if (eventRef.current && eventRef.current.clientWidth) {
			const newWidth = eventRef.current.clientWidth;
			setWidth(newWidth);
		}
	};

	useEffect(() => {
		if (settingsOtt?.thumbnail_bg_color) {
			const bg = `linear-gradient(180deg, rgba(26, 54, 141, 0) 10.64%, ${settingsOtt.thumbnail_bg_color} 95.86%)`;
			setStyles((prevState) => ({...prevState, background: bg}));
		}
		if (settingsOtt?.thumbnail_font_color) {
			setStyles((prevState) => ({...prevState, color: settingsOtt.thumbnail_font_color}));
		}
	}, [settingsOtt]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 150);
		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		window.addEventListener("resize", getWidth);
		return () => {
			window.removeEventListener("resize", getWidth);
		};
	}, []);

	let settings = {
		infinite: false,
		speed: 500,
		slidesToShow: Math.ceil((isEmpty(width) ? 700 : width) / 420),
		slidesToScroll: itemsPerPage,
		prevArrow: <LeftArrow />,
		nextArrow: <RightArrow />,
		draggable: true,
		centerPadding: 30,
		initialSlide: 0,
		afterChange: (index) => {
			if(document.querySelector(".d-none.arrow-slideshow.right")){
				nextPageHandler();
			}
		}
	};

	useEffect(() => {
		setEventsArray(events)
	}, [events]);

	useEffect(() => {
		if (!isEmpty(width)) {
			setItemsPerPage(Math.ceil(width / 420));
		} else {
			setItemsPerPage(5);
		}
	}, [width]);

	const nextPageHandler = () => {
		if (hasNexPage !== null) {
			axios
				.get(`${process.env.REACT_APP_API_URL}getallLives&p=${countPage}&n=10&t=1`)
				.then((res) => {
					console.log("get next (" + countPage + ") page for events");
					setHasNextPage(res.data.live.nextpage);
					setCountPage(countPage + 1);
					setEventsArray([...eventsArray, ...res.data.live.events]);
				})
				.catch((error) => {
					console.log(error)
				});
		}

	};

	if (eventsArray.length > 0) {
		return (
			<div className="events filter">
				<div className="titles">
					<section
						className="flex-between m-auto"
					>
						<h3 className="live">
							{title && title}
						</h3>
						{showMore && (
							<NavLink to="/directs" className="item-menu">
								<h5>
									{
										translations && !isEmpty(translations["more_events"])
											? translations["more_events"]
											: "More events"
									}

									<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M0.665363 6.66536L8.7787 6.66536L5.05203 10.392L5.9987 11.332L11.332 5.9987L5.9987 0.665364L5.0587 1.60536L8.7787 5.33203L0.665363 5.33203L0.665363 6.66536Z" fill="#F41B3B" stroke="#F41B3B" strokeWidth="0.2"/>
									</svg>
								</h5>
							</NavLink>
						)}

					</section>
				</div>
				<div ref={eventRef}>
					{eventsArray.length > 0 && (
						<Slider
							ref={slider}
							{...settings}
							className="slides-container hide-scroll customScroll"
						>
							{eventsArray.map((event, index) => {
								//RETURN EACH EVENT
								return (
									<Link
										key={index}
										draggable="false"
										rel="noopener noreferrer"
										to={`/live/${event.id}`}
										onClick={() => window.scrollTo(0, 0)}
									>
										<div
											className={`event ${event.status ? "issue" : ""}`}
											key={event.id}
										>
											<img
												src={!isMobile ? event.pic_desktop : event.pic}
												alt={`Apercu de ${event.title}`}
											/>
											<div className="title-container" style={styles}>
												<h4>{`${event.title}`}</h4>
											</div>
										</div>
									</Link>
								);
							})}
						</Slider>
					)}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default Events;
