import { combineReducers } from "redux";
import {
	GET_TRANSLATIONS
} from "../actions/translations.action";

const initialState = [];

function translationsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_TRANSLATIONS:
			return action.payload;
		default:
			return state;
	}
}

export default combineReducers({ translationsReducer });
