import React, {Fragment, useContext, useEffect, useState} from "react";
import { useSelector} from "react-redux";
import { MyContext } from "../../contexts/MyContext";
import { isEmpty } from "../Utils";

const Preferences = () => {
	const { rootState, updateEmail, logoutUser } = useContext(MyContext);
	const { theUser } = rootState;
	const [email, setEmail] = useState(null);
	const [errorMail, setErrorMail] = useState(false);
	const [allow, setAllow] = useState(false);
	const [stateEdit, setStateEdit] = useState(false);
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const translations = useSelector(
		(state) => state.langReducer.translationsReducer.translate
	);

	useEffect(() => {
		if (!isEmpty(theUser)) {
			setEmail(theUser.email);
		}
	}, [theUser]);

	const submitForm = async (event) => {
		event.preventDefault();
		if (allow) {
			const data = await updateEmail(email);
			setErrorMail(false);
			if (data.result === "ok") {
				logoutUser();
			} else {
				setErrorMail(true);
				document.querySelector("#email").parentElement.classList.add("error");
				console.log("erreur : " + data.message);
			}
		}
	};

	//HANDLE ERROR
	useEffect(() => {
		if (!isEmpty(email) && stateEdit) {
			email.length < 2 ||
			!email.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
				? document.querySelector("#email").parentElement.classList.add("error")
				: document.querySelector("#email").parentElement.classList.remove("error");
		}

	}, [email, stateEdit]);

	const submitHandle = () => {
		isEmpty(email) &&
		document.querySelector("#email").parentElement.classList.add("error");

		if (document.querySelectorAll(".input.error").length > 0) {
			setAllow(false);
			document.querySelectorAll(".input.error").forEach((elm) => {
				elm.classList.remove("error");
				setTimeout(() => {
					elm.classList.add("error");
				}, 100);
			});
		} else {
			setAllow(true);
		}
	};

	return (
		<section className="preference block" style={{
			color: settings?.menu_text_color ? settings.menu_text_color : "white",
			background: !isEmpty(settings) ? settings.accent_color : ""
		}}>
			<div className="title">
				<h3>
					{translations && !isEmpty(translations["register_email_ph"])
						? translations["register_email_ph"]
						: "Email"
					}
				</h3>
				<span className="edit" onClick={() => setStateEdit(!stateEdit)} style={{background: !isEmpty(settings) ? settings.hover_color : ""}}>
					{translations && !isEmpty(translations["button_edit"])
						? translations["button_edit"]
						: "Edit"
					}
				</span>
			</div>

			<article>
				{stateEdit ? (
					<form onSubmit={submitForm} noValidate>
						<div className="input">
							<label>
								{translations && !isEmpty(translations["register_email_ph"])
									? translations["register_email_ph"]
									: "email"
								} *
							</label>
							<input
								type="email"
								name="email"
								id="email"
								placeholder={translations && !isEmpty(translations["register_email_ph"])
									? translations["register_email_ph"]
									: "email"
								}
								value={email ? email : " "}
								required
								autoComplete="email"
								onChange={(e) => setEmail(e.target.value)}
							/>
							{errorMail && (
								<div className="errorDisplay">
									{translations && !isEmpty(translations["register_email_error"])
										? translations["register_email_error"]
										: "Register email error"}
								</div>
							)}
						</div>

						<div className="submit">
							<input
								style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
								type="submit"
								value={
									translations && !isEmpty(translations["button_update"])
										? translations["button_update"]
										: "Update"
								}
								onClick={submitHandle}
							/>
							<div id="validUpdate">
								{translations && !isEmpty(translations["text_updated"])
									? translations["text_updated"]
									: "The record has been updated"
								}
							</div>
						</div>
					</form>
				) : (
					<Fragment>
						<label>
							{translations && !isEmpty(translations["register_email_ph"])
								? translations["register_email_ph"]
								: "email"
							} *
						</label>
						<p>
							{!isEmpty(theUser) && theUser.email}
						</p>
					</Fragment>
				)}

			</article>

		</section>
	);
};

export default Preferences;
