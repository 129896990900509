import Routes from "./components/Routes/Routes";
//HELMET
import { Helmet } from "react-helmet-async";
import { useMatomo } from '@jonkoops/matomo-tracker-react'
//CACHE
import withClearCache from "./ClearCache";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSetting} from "./actions/setting.action";
import {isEmpty} from "./components/Utils";
import ToastModal from "./components/ToastModal";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";
import GoogleFontLoader from "./loaders/Fontloader";
loadStripe.setLoadParameters({advancedFraudSignals: false})
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC}`);

const ClearCacheComponent = withClearCache(MainApp);

function App() {
	return <ClearCacheComponent />;
}

function MainApp() {
	const dispatch = useDispatch();
	const { trackPageView } = useMatomo();
	const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
	const [styles, setStyles] = useState({});
	const [configFonts, setConfigFonts] = useState([]);

	useEffect(() => {
		if (settings?.font_name) {
			setConfigFonts((prevState) => ([...prevState, {font: settings?.font_name, weights: [400, 600, 900]}]));
		}

		if (settings?.general_text_color) {
			setStyles((prevState) => ({...prevState, color: settings.general_text_color}));
		}

		if (settings?.background_color) {
			setStyles((prevState) => ({...prevState, background: settings.background_color}));
		}

		if (settings?.font_name) {
			setStyles((prevState) => ({...prevState, fontFamily: `${settings?.font_name}, sans-serif`}));
		} else {
			setStyles((prevState) => ({...prevState, fontFamily: `Gotham , sans-serif`}));
		}
	}, [settings]);

	useEffect(() => {
		dispatch(getSetting());

		if (process.env.REACT_APP_ENV === "production" && navigator.userAgent !== "ReactSnap") {
			trackPageView()
		}
		// eslint-disable-next-line
	}, []);

	return (
		<main className="App" style={styles}>

			{configFonts.length > 0 && (
				<GoogleFontLoader
					fonts={configFonts}
				/>
			)}

			<Helmet>
				<title>{process.env.REACT_APP_CLIENT_NAME}</title>

				{settings?.favicon_ico && (
					<link rel="icon" href={`${process.env.REACT_APP_CACHE_SERVER}/images${settings.favicon_ico}`}/>
				)}
				{settings?.favicon_ico512x512 && (
					<link rel="icon" sizes="512x512" href={`${process.env.REACT_APP_CACHE_SERVER}/images${settings?.favicon_ico512x512}`}/>
				)}
				{settings?.favicon_ico192x192 && (
					<link rel="icon" sizes="192x192" href={`${process.env.REACT_APP_CACHE_SERVER}/images${settings?.favicon_ico192x192}`}/>
				)}
				{settings?.favicon_ico32x32 && (
					<link rel="icon" sizes="32x32" href={`${process.env.REACT_APP_CACHE_SERVER}/images${settings?.favicon_ico32x32}`}/>
				)}

				{settings?.favicon_ico16x16 && (
					<link rel="icon" sizes="16x16" href={`${process.env.REACT_APP_CACHE_SERVER}/images${settings?.favicon_ico16x16}`}/>
				)}

				{settings?.favicon_touch && (
					<link rel="apple-touch-icon" sizes="57x57" href={`${process.env.REACT_APP_CACHE_SERVER}/images${settings?.favicon_touch}`}/>
				)}

				<meta property="og:image" content={`${process.env.REACT_APP_CACHE_SERVER}/images/logo.png`} />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
			</Helmet>

			<ToastModal/>

			{!isEmpty(settings) && !isEmpty(settings.subscription_features) && settings.subscription_features === "yes" ? (
				<Elements stripe={stripePromise}>
					<Routes />
				</Elements>
			) : (
				<Routes />
			)}


		</main>
	);
}

export default App;
