import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import CheckoutForm from "./CheckoutForm";
import {isEmpty, isNumeric} from "./Utils";
import Checkbox from "react-custom-checkbox";
import {useDispatch, useSelector} from "react-redux";
import {getCustomer, getProducts} from "../actions/stripe.action";
import {toggleModal} from "../actions/modal.action";
import {MyContext} from "../contexts/MyContext";
import EditPayment from "./account/subscription/submenu/EditPayment";
import PriceSelector from "./stripe/PriceSelector";

const Stripe = ({poster, entityType, entityId, minHierarchy, priceForView}) => {
    const { createSubscribe } = useContext(MyContext);
    const settings = useSelector((state) => state.settingReducer.getSettingReducer.config);
    const stripeReducer = useSelector((state) => state.stripeReducer);
    const products = stripeReducer.getProductsReducer;
    const customer = stripeReducer.getCustomerReducer;
    const dispatch = useDispatch();

    const [type, setType] = useState(minHierarchy ? "subscribe" : "payForView");
    const [prices, setPrices] = useState([]);
    const [checkedPriceId, setCheckedPriceId] = useState(null);
    const [checkedPlanId, setCheckedPlanId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openEditPayment, setOpenEditPayment] = useState(false);
    const [wait, setWait] = useState(false);
    const [accountDeleted, setAccountDeleted] = useState(false);

    useEffect(() => {
        if(customer?.deleted !== true) {
            setAccountDeleted(true);
        } else {
            setAccountDeleted(false);
        }
    }, [customer]);

    useEffect(() => {
        dispatch(getCustomer());
        dispatch(getProducts(1));
    }, [dispatch]);

    const filteredProducts = useMemo(() => {
        return products.filter((product) => {
            return product?.metadata?.hierarchy_number >= minHierarchy
        })
    }, [minHierarchy, products]);

    useEffect(() => {
        if (openModal && products) {
            products.forEach((product) => {
                if (product.id === checkedPlanId) {
                    const newArrayOfObj = product.prices.map((item) => {
                        return {value: item.id, label: (item.unit_amount / 100).toLocaleString('eu-EU', {style: 'currency',currency: 'EUR'}) + "/" + (!isEmpty(item?.recurring?.interval) ? item.recurring.interval : "one time"), ...item };
                    });
                    setPrices(newArrayOfObj);
                }
            })
        }
    }, [checkedPlanId, openModal, products]);

    const onChangePlan = async () => {
        if (!isEmpty(customer.id) && !isEmpty(checkedPriceId)) {
            setWait(true);
            let paymentResult = await createSubscribe(customer.id, checkedPriceId);

            setOpenModal(false);

            if (paymentResult.result) {
                if (paymentResult.result === "empty_payments_methods") {

                    setOpenEditPayment(true);

                } else if (paymentResult.result !== "succeeded" && paymentResult.result !== "ok") {
                    dispatch(toggleModal(
                        {
                            opened: true,
                            success: false,
                            text: !isEmpty(paymentResult.error) ? paymentResult.error : "Subscribe error"
                        })
                    );
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    dispatch(getCustomer());

                    dispatch(toggleModal(
                        {
                            opened: true,
                            success: true,
                            text: "Subscribe success"
                        })
                    );
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            } else {
                dispatch(toggleModal(
                    {
                        opened: true,
                        success: false,
                        text: "Subscribe error"
                    })
                );
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
            setWait(false);

        } else {
            console.log("error")
        }

    }

    return (
        <Fragment>
            {accountDeleted !== false ? (
                <div className="h-100 parameters-container mb-0">
                    <div className="poster-live m-auto">
                        <img src={poster} alt=""/>
                    </div>

                    <div className="stripe-info-block subscription">

                        {wait && (
                            <div className="success-animation">
                                <div className="loader">
                                    <div className={wait === "succeeded" ? "circle-loader load-complete" : "circle-loader"}>
                                        <div className="checkmark draw"/>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="stripe-tabs custom-radio d-flex justify-content-space-between" >
                            {minHierarchy && (
                                <div className={type === "subscribe" ? "active" : ""} onClick={() => setType("subscribe")}>
                                    <span>Subscribe</span>
                                </div>
                            )}

                            {priceForView && (isNumeric(priceForView.entity.price) || isNumeric(priceForView.playlist.price)) &&  (
                                <div className={type === "payForView" ? "active" : ""} onClick={() => setType("payForView")}>
                                    <span>Pay for view</span>
                                </div>
                            )}

                        </div>

                        {openEditPayment && (
                            <EditPayment
                                onClose={() => setOpenEditPayment(!openEditPayment)}
                                onSuccess={onChangePlan}
                                setAsDefault={true}
                            />
                        )}

                        {openModal && (
                            <PriceSelector
                                wait={wait}
                                prices={prices}
                                onChangePlan={onChangePlan}
                                setCheckedPriceId={setCheckedPriceId}
                                closeModal={() => setOpenModal(!openModal)}
                                styles={{zIndex: 10, width: "70%"}}
                            />
                        )}

                        <div className="stripe-content">
                            {type === "subscribe" ? (
                                <div>
                                    {filteredProducts && filteredProducts.map((product) => {
                                        return (
                                            <div className="element plans" key={product.id} id={product.id}>

                                                <div className="d-flex">
                                                    <div className="checkbox my-auto mx-2">
                                                        <Checkbox
                                                            checked={checkedPlanId === product.id}
                                                            onChange={() => setCheckedPlanId((prev) => prev === product.id ? null : product.id)}
                                                            borderColor="#4f49a1"
                                                            borderWidth="1px"
                                                            containerClassName="d-block"
                                                            icon={
                                                                <svg fill="#4caf50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>
                                                            }
                                                        />
                                                    </div>
                                                    <div className="content ml-2">
                                                        <h2 className="my-2 text-bold">{product.name}</h2>
                                                        <p className="mb-2">{product.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })}

                                    <button
                                        disabled={isEmpty(checkedPlanId)}
                                        className="btn btn-stripe mt-2"
                                        onClick={() => setOpenModal(!openModal)}
                                        style={{background: !isEmpty(settings) ? settings.accent_color : ""}}
                                    >Subscribe</button>
                                </div>
                            ) : priceForView ? (
                                <CheckoutForm
                                    entityType={entityType}
                                    entityId={entityId}
                                    onUpdate={(status) => setWait(status)}
                                    customerId={customer.id}
                                    price={priceForView}
                                />
                            ) : ""}

                        </div>
                    </div>
                </div>
            ) : (
                <div className="h-100 parameters-container mb-0">
                    <div className="poster-live m-auto">
                        <img src={poster} alt=""/>
                    </div>

                    <div className="stripe-info-block subscription p-4">
                        <div>Your stripe account has been deleted.</div><br/>
                        <div>Please, contact the administrator</div>
                    </div>
                </div>
            )}

        </Fragment>

    );
};

export default Stripe;